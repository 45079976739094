import React, { useEffect, useState } from "react";
import SkillLogo from "../../assets/logo_white.svg";
import HumbergerMenu from "../../assets/icon/menuHumberger.svg";
import GlobleIcon from "../../assets/icon/GlobeIcon.svg";
import UserMenu from "../../components/UserMenu";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {  logoutUser } from "../../redux/slice/authSlice";
import { fetchUserData } from "../../redux/slice/profileSlice";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector((state) => state.profile);

  const handleGlobeClick = () => {
    console.log("Globe clicked");
  };

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()).unwrap();
      window.location.reload();
      navigate("/", { replace: true }); // Use replace to prevent going back to authenticated routes
    } catch (error) {
      console.error("Logout failed:", error);
      // Optionally show error notification to user
    }
  };

  const menuItems = [
    { label: "Einstellungen", href: "/profile" },
    { label: "Logout", onClick: handleLogout },
  ];
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-[#083D4E] text-white">
      <div className="container mx-auto flex items-center justify-between py-[7px] h-[60px]">
        <a href="/" className="inline-block w-[104px] relative z-20">
          <img src={SkillLogo} alt="SKILLS Logo" className="w-full" />
        </a>
        <button
          className="w-[40px] h-[40px] rounded-full bg-gray-700 text-white lg:hidden block"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <img src={HumbergerMenu} alt="Globe" className="w-full" />
        </button>
        <ul
          className={`lg:flex lg:relative lg:bg-transparent lg:w-auto left-0 lg:top-0 p-5 items-center xl:gap-[30px] z-10 gap-3 transition
        ${
          isMenuOpen ? "block" : "hidden"
        } absolute bg-[#083D4E] w-full top-[60px]`}
        >
          <li>
            <a
              href="/"
              className="transition-all duration-200 px-[10px] py-[9px] text-base leading-[19px] font-medium rounded-[10px] hover:bg-[#317A9A]"
            >
              Home
            </a>
          </li>
          <li className="relative">
            <a
              href="/"
              className="transition-all duration-200 px-[10px] py-[9px] text-base leading-[19px] font-medium rounded-[10px] hover:bg-[#317A9A]"
            >
              Organisation
            </a>
          </li>
          <li>
            <a
              href="/"
              className="transition-all duration-200 px-[10px] py-[9px] text-base leading-[19px] font-medium rounded-[10px] hover:bg-[#317A9A]"
            >
              Kompetenzen
            </a>
          </li>
          <li>
            <a
              href="/jobs"
              className="transition-all duration-200 px-[10px] py-[9px] text-base leading-[19px] font-medium rounded-[10px] hover:bg-[#317A9A]"
            >
              Arbeitsplätze
            </a>
          </li>
          <li>
            <a
              href="/"
              className="transition-all duration-200 px-[10px] py-[9px] text-base leading-[19px] font-medium rounded-[10px] hover:bg-[#317A9A]"
            >
              Mitarbeiter
            </a>
          </li>
          <li>
            <a
              href="/"
              className="transition-all duration-200 px-[10px] py-[9px] text-base leading-[19px] font-medium rounded-[10px] hover:bg-[#317A9A]"
            >
              Team
            </a>
          </li>
          <li>
            <a
              href="/"
              className="transition-all duration-200 px-[10px] py-[9px] text-base leading-[19px] font-medium rounded-[10px] hover:bg-[#317A9A]"
            >
              Weitere
            </a>
          </li>
        </ul>
        <div className="relative lg:block hidden">
          <div className="flex items-center space-x-4">
            <button className="w-[40px] h-[40px] rounded-full bg-gray-700 text-white">
              <img src={GlobleIcon} alt="Globe" className="w-full" />
            </button>
            <UserMenu
              username="admin"
              menuItems={menuItems}
              onGlobeClick={handleGlobeClick}
              profilePic={userData?.profileImage}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
