import React from "react";
import MainLogo from "../../../assets/skills_logo.svg.svg";
import { InputField } from "../../../components/InputField";
import { useNavigate } from "react-router-dom";
import PasswordInput from "../../../components/PasswordInput";
import toast from "react-hot-toast";
import Loader from "../../../components/Loader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../redux/slice/authSlice";
import DefaultAuthLayout from "../../../components/AuthLayout";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Ungültige E-Mail")
      .required("E-Mail ist erforderlich"),
    password: Yup.string()
      .min(6, "Das Passwort muss mindestens 6 Zeichen lang sein")
      .required("Passwort ist erforderlich"),
  });
  const handleLogin = async (values, { resetForm }) => {
    try {
      const res = await dispatch(loginUser(values)).unwrap();
      localStorage.setItem("token", res.token);
      toast.success("Login successful!");
      resetForm();
      navigate("/dashboard");
    } catch (err) {
      if (err.message) {
        toast.error(`Error logging in: ${err.message}`);
      } else {
        toast.error(
          "Login failed. Please check your credentials and try again."
        );
      }
    }
  };
  return (
    <>
      <div className="flex items-center">
        <DefaultAuthLayout />
        <div className="lg:w-[40%] md:w-[50%] w-full items-center h-screen bg-white">
          <div className="w-full max-w-[500px] mx-auto sm:p-8 p-6 bg-white mt-[110px]">
            <div className="text-center mb-10">
              <img
                src={MainLogo}
                alt="8Skills Logo"
                className="mx-auto mb-[30px]"
              />
              <h2 className="md:text-2xl text-xl font-bold text-[#333]">
                Login
              </h2>
            </div>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={LoginSchema}
              onSubmit={handleLogin}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <Form>
                  <div className="mb-5">
                    <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                      E-Mail
                    </label>
                    <InputField
                      type="email"
                      name="email"
                      placeholder="Geben Sie Ihre E-Mail-ID ein"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email && (
                      <div className="flex items-center space-x-2 text-red-500 text-sm m-2">
                        <span>{errors.email}</span>
                      </div>
                    )}
                  </div>

                  <div className="mb-5">
                    <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                      Passwort
                    </label>
                    <PasswordInput
                      name="password"
                      placeholder="Geben Sie Ihr Passwort ein"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.password && touched.password && (
                      <div className="flex items-center space-x-2 text-red-500 text-sm m-2">
                        <span>{errors.password}</span>
                      </div>
                    )}
                  </div>

                  <div className="text-right mb-5">
                    <a
                      href="/forgot-password"
                      className="text-sm text-[#083D4E] hover:underline"
                    >
                      Passwort vergessen?
                    </a>
                  </div>

                  <div className="text-center mt-5">
                    <button
                      type="submit"
                      disabled={isSubmitting || loading}
                      className="max-w-[190px] w-full bg-[#BF5547] text-white text-base leading-5 py-3 px-7 rounded-md"
                    >
                      {loading || isSubmitting ? (
                        <div className="btnLoading outline-none">
                          <Loader />
                        </div>
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="mt-7 text-center text-sm text-[#888888]">
              Sie haben noch kein Konto??
              <a
                href="/register"
                className="text-[#083D4E] font-medium hover:underline"
              >
                Erstellen Sie eins
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
