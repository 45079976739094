import React, { useEffect, useState } from "react";
import { InputField } from "../../../components/InputField";
import { ReactComponent as DropdownArrow } from "../../../assets/icon/DownArrow.svg";
import { ReactComponent as SaveIcon } from "../../../assets/icon/SaveIcon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icon/CloseIcon.svg";
import { useSelector } from "react-redux";

export default function ExperienceEditForm({ isOpen, onClose }) {
  const { userData } = useSelector((state) => state.profile);
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    isCurrent: false,
    company: "",
    position: "",
    location: "",
  });

  const formatDateToInput = (dateString) => {
    if (!dateString || dateString.includes("-")) return dateString;
    const [day, month, year] = dateString.split(".");
    if (day && month && year) {
      return `${year}-${month}-${day}`;
    } else {
      // Return an empty string if format is invalid
      return "";
    }
  };

  useEffect(() => {
    const experience = userData.experience[0];
    setFormData({
      startDate: formatDateToInput(experience?.startdate || ""),
      endDate: formatDateToInput(experience?.enddate || ""),
      company: experience?.company || "",
      position: experience?.position || "",
      location: experience?.location || "",
    });
  }, [userData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async () => {
    // try {
    //   const updatedData = {
    //     experience: [
    //       {
    //         startdate: formData.startDate,
    //         enddate: formData.endDate,
    //         company: formData.company,
    //         position: formData.position,
    //         location: formData.location,
    //       },
    //     ],
    //   };
    //   await dispatch(updateUserProfile(updatedData)).unwrap();
    //   dispatch(setIsModalOpen(false));
    //   dispatch(fetchUserData());
    // } catch (error) {
    //   console.log("error: ", error);
    // }
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
      <div className="bg-white rounded-[15px] shadow-lg max-w-[830px] w-[calc(100%-30px)] max-h-[95vh] overflow-auto">
        <div className="flex justify-between items-center px-5 py-[15px] border-b border-[#CCCCCC]">
          <h3 className="text-2xl font-bold">Erfahrung bearbeiten</h3>
        </div>
        <div className="p-5">
          <div className="grid sm:grid-cols-2 gap-4 mb-5">
            <div>
              <label className="block text-[#333333] sm:text-base text-sm font-medium mb-[10px]">
                Aus
              </label>
              <div className="relative">
                <input
                  type="date"
                  className="w-full px-[10px] py-[9px] border rounded-[7px] border-[#CCCCCC] text-sm font-normal leading-[18px]"
                  placeholder="Wählen Sie das Startdatum"
                  name="startDate"
                  value={formData.startDate}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div>
              <label className="block text-[#333333] sm:text-base text-sm font-medium mb-[10px]">
                Zu
              </label>
              <div className="relative">
                <input
                  type="date"
                  className="w-full px-[10px] py-[9px] border rounded-[7px] border-[#CCCCCC] text-sm font-normal leading-[18px]"
                  placeholder="Wählen Sie das Enddatum aus"
                  name="endDate"
                  value={formData.endDate}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="mb-5">
            <label className="flex items-center w-full gap-[10px]">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <div className="font-medium text-base text-[#333333]">
                Arbeite derzeit hier
              </div>
            </label>
          </div>
          <div className="grid sm:grid-cols-2 gap-4 mb-5">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name der Firma
              </label>
              <div className="relative">
                <InputField
                  type="text"
                  placeholder="Schreiben Sie den Firmennamen"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Bezeichnung
              </label>
              <div className="relative">
                <select
                  className="w-full pl-3 pr-10 py-[10px] border border-[#CCCCCC] leading-[18px] text-sm rounded-md appearance-none"
                  name="position"
                  value={formData.position}
                  onChange={handleChange}
                >
                  <option value="">Wählen Sie Ihre Bezeichnung aus</option>
                  <option value="manager">Manager</option>
                  <option value="developer">Developer</option>
                  <option value="designer">Designer</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <DropdownArrow />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Firmenadresse
            </label>
            <div className="relative">
              <textarea
                name="location"
                value={formData.location}
                onChange={handleChange}
                className="w-full pl-2 pr-3 py-2 border border-gray-300 rounded-md text-gray-400"
                rows="3"
                placeholder="Firmenadresse schreiben"
              ></textarea>
            </div>
          </div>
          <div className="flex justify-end sm:gap-[20px] gap-[10px]  mt-5">
            <button
              className="text-[#333333] text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
              onClick={onClose}
            >
              <CloseIcon />
              Stornieren
            </button>
            <button
              className="bg-[#317A9A] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
              onClick={handleSubmit}
            >
              <SaveIcon />
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
