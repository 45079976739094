import React from "react";
import { InputField } from "../../../components/InputField";
import MainLogo from "../../../assets/skills_logo.svg.svg";
import Select from "../../../components/Select";
import PasswordInput from "../../../components/PasswordInput";
import toast from "react-hot-toast";
import Loader from "../../../components/Loader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../../../redux/slice/userSlice";
import DefaultAuthLayout from "../../../components/AuthLayout";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters long")
      .required("Required"),
    usertype: Yup.string().required("Please select an option")
  });

  const handleRegister = async (values, { resetForm }) => {
    try {
      const resultAction = await dispatch(registerUser(values));
      if (registerUser.fulfilled.match(resultAction)) {
        toast.success("Register successful!");
        navigate("/", { replace: true });
        resetForm();
      } else if (registerUser.rejected.match(resultAction)) {
        toast.error(resultAction.payload);
      }
    } catch (err) {
      console.error("Error logging in", err);
    }
  };
  const selectOptions = [
    { value: "einzelperson", label: "Einzelperson" },
    { value: "unternehmen", label: "Unternehmen" }
  ];
  return (
    <div className="flex items-center">
      <DefaultAuthLayout />
      <div className="lg:w-[40%] md:w-[50%] w-full items-center h-screen bg-white">
        <div className="w-full max-w-[500px] mx-auto sm:p-8 p-6 bg-white mt-[110px]">
          <div className="text-center mb-10">
            <img
              src={MainLogo}
              alt="8Skills Logo"
              className="mx-auto mb-[30px]"
            />
            <h2 className="md:text-2xl text-xl font-bold text-[#333]">
              Anmelden
            </h2>
          </div>
          <Formik
            initialValues={{ email: "", password: "", usertype: "" }}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              setFieldValue,
              isSubmitting
            }) => (
              <Form>
                <div className="mb-5">
                  <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                    Email
                  </label>
                  <InputField
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Geben Sie Ihren Benutzernamen ein"
                  />
                  {touched.email && errors.email ? (
                    <div className="flex items-center space-x-2 text-red-500 text-sm m-2">
                      {errors.email}
                    </div>
                  ) : null}
                </div>

                <div className="mb-5">
                  <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                    Passwort
                  </label>
                  <div className="relative mb-3">
                    <PasswordInput
                      name="password"
                      placeholder="Geben Sie Ihr Passwort ein"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.password && errors.password ? (
                      <div className="flex items-center space-x-2 text-red-500 text-sm m-2">
                        {errors.password}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="relative mb-5">
                  <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">
                    Du bist
                  </label>
                  <Select
                    options={selectOptions}
                    value={values.usertype}
                    onChange={(e) => setFieldValue("usertype", e.target.value)}
                    placeholder="Auswahl"
                  />
                  {touched.usertype && errors.usertype ? (
                    <div className="flex items-center space-x-2 text-red-500 text-sm m-2">
                      {errors.usertype}
                    </div>
                  ) : null}
                </div>

                <div className="text-center mt-5">
                  <button
                    type="submit"
                    className="max-w-[190px] w-full bg-[#BF5547] text-white text-base leading-5 py-3 px-7 rounded-md"
                  >
                    {loading || isSubmitting ? (
                      <div className="btnLoading outline-none">
                        <Loader />
                      </div>
                    ) : (
                      "Anmelden"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="mt-7 text-center text-sm text-[#888888]">
            Haben Sie ein Konto??{" "}
            <a href="/" className="text-[#083D4E] font-medium hover:underline">
              Melden Sie sich hier an
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
