import React from "react";
import SkillLogo from "../../assets/skills_logo.svg.svg";

const Footer = () => {
  return (
    <footer className="bg-sky-100 text-[#083D4E] relative">
      <div className="container">
        <div className="flex items-center md:flex-row flex-col md:py-[38px] md:py-[38px] md:px-0 sm:px-[90px] px-[10px] md:gap-0 gap-5">
          <a href="/" className="inline-block md:w-[33.33%] w-full md:p-0 p-5"><img src={SkillLogo} alt="Logo" /></a>
          <div className="md:w-[33.33%] w-full md:p-0 p-5">
            <h3 className="text-[#083D4E] mb-[15px] md:text-[24px] sm:text-[20px] text-[18px] md:leading-[29px] font-bold">Rechtliches</h3>
            <ul className="text-sm">
              <li><a className="text-base text-[#083D4E] mb-[10px] font-medium" href="/">Impressum</a></li>
              <li><a className="text-base text-[#083D4E] mb-[10px] font-medium" href="/">Datenschutz</a></li>
              <li><a className="text-base text-[#083D4E] mb-[10px] font-medium" href="/">AGB</a></li>
            </ul>
          </div>
          <div className="md:w-[33.33%] w-full md:p-0 p-5">
            <h3 className="text-[#083D4E] mb-[15px] md:text-[24px] sm:text-[20px] text-[18px] md:leading-[29px] font-bold">Benutzer</h3>
            <ul className="text-sm">
              <li><a className="text-base text-[#083D4E] mb-[10px] font-medium" href="/">Login</a></li>
              <li><a className="text-base text-[#083D4E] mb-[10px] font-medium" href="/">Anmelden</a></li>
              <li><a className="text-base text-[#083D4E] mb-[10px] font-medium" href="/">Passwort vergessen</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-[#083D4E] text-white text-sm py-[10px] text-center">
        © 2023 Connectum GmbH. Alle Rechte vorbehalten.
      </div>
    </footer>
  );
};

export default Footer;
