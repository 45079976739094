/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";

export async function useGetApi(url) {
  const token = localStorage.getItem("token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const response = await axios.get(url, { headers });
    return response?.data ? response?.data : response;
  } catch (error) {
    throw error;
  }
}

export async function usePostApi(url, requestData) {
  const token = localStorage.getItem("token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const response = await axios.post(url, requestData, { headers });
    return response;
  } catch (error) {
    throw error;
  }
}

export async function usePatchApi(url, requestData) {
  const token = localStorage.getItem("token");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    const response = await axios.patch(url, requestData, { headers });

    return response?.data ? response?.data : response;
  } catch (error) {
    return error?.response;
  }
}
