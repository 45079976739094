import React, { useEffect, useState, useMemo } from "react";
import CloseIcon from "../../../../assets/icon/CloseIcon.svg";
import SaveIcon from "../../../../assets/icon/SaveIcon.svg";
import PasswordInput from "../../../../components/PasswordInput";
import { ReactComponent as CheckmarkCircle } from "../../../../assets/icon/CheckmarkCircle.svg";
import { ReactComponent as Checkmark } from "../../../../assets/icon/Checkmark.svg";

// Utility function for validating password requirements
const validatePassword = (password) => {
  return {
    length: password.length >= 8,
    case: /[a-z]/.test(password) && /[A-Z]/.test(password),
    special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  };
};

// Password requirements text
const requirementsText = {
  length: "Mindestens 8 Zeichen lang",
  case: "Mindestens ein Kleinbuchstabe und Großbuchstabe",
  special: "Mindestens ein Sonderzeichen",
};

// Password change form component
const PasswordChangeForm = ({
  passwords,
  handleChange,
  handleSubmit,
  errors,
  isValid,
}) => (
  <form className="p-5" onSubmit={handleSubmit}>
    <PasswordInput
      id="current-password"
      label="Aktuelles Passwort"
      name="current"
      value={passwords.current}
      onChange={handleChange}
    />
    <PasswordInput
      id="new-password"
      label="Neues Passwort"
      name="new"
      value={passwords.new}
      onChange={handleChange}
    />
    <PasswordInput
      id="confirm-password"
      label="Passwort bestätigen"
      name="confirm"
      value={passwords.confirm}
      onChange={handleChange}
      error={errors.confirm}
    />
    <div className="flex justify-end gap-[20px]">
      <button
        type="button"
        className="text-[#333333] text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
      >
        <img className="inline" src={CloseIcon} alt="Abbrechen" sizes={20} />
        Abbrechen
      </button>
      <button
        type="submit"
        disabled={!isValid}
        className="bg-[#317A9A] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
      >
        <img className="inline" src={SaveIcon} alt="Speichern" sizes={20} />
        Speichern
      </button>
    </div>
  </form>
);

// Password requirements list component
const PasswordRequirements = ({ requirements }) => (
  <ul className="grid gap-[20px]">
    {Object.entries(requirementsText).map(([key, text]) => (
      <li
        key={key}
        className="flex items-center md:gap-[30px] gap-[20px] text-sm leading-[18px] text-[#333333]"
      >
        {requirements[key] ? <CheckmarkCircle /> : <Checkmark />}
        <span>{text}</span>
      </li>
    ))}
  </ul>
);

// Main Security component
const Security = () => {
  const [passwords, setPasswords] = useState({
    current: "",
    new: "",
    confirm: "",
  });
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  // Memoized initial password requirements state
  const initialRequirements = useMemo(() => validatePassword(""), []);

  const [passwordRequirements, setPasswordRequirements] = useState(
    initialRequirements
  );

  useEffect(() => {
    const newPasswordValidation = validatePassword(passwords.new);
    setPasswordRequirements(newPasswordValidation);

    const newErrors = {};
    if (passwords.new !== passwords.confirm) {
      newErrors.confirm = "Passwörter stimmen nicht überein";
    }
    setErrors(newErrors);
    setIsValid(
      Object.keys(newErrors).length === 0 &&
        Object.values(newPasswordValidation).every((req) => req)
    );
  }, [passwords.new, passwords.confirm]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      console.log("Password change submitted:", passwords);
    }
  };

  return (
    <div className="grid lg:grid-cols-2 gap-5">
      <div>
        <div className="flex justify-between items-center px-5 py-[15px] border-b border-[#CCCCCC]">
          <h3 className="text-lg font-bold">Kennwort ändern</h3>
        </div>
        <PasswordChangeForm
          passwords={passwords}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          errors={errors}
          isValid={isValid}
        />
      </div>
      <div>
        <div className="flex justify-between items-center px-5 py-[15px] border-b border-[#CCCCCC]">
          <h3 className="text-lg font-bold">Passwortanforderung</h3>
        </div>
        <div className="p-5">
          <PasswordRequirements requirements={passwordRequirements} />
        </div>
      </div>
    </div>
  );
};

export default Security;
