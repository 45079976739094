export const useHumaticsCalculations = () => {
  const shannonFormula = (values) => {
    if (values.length === 0) return 0;
    const m = values.reduce((a, b) => a + b, 0);
    let hp = 0.0;
    for (const value of values) {
      if (value > 0) {
        const v = value / m;
        hp += (v * Math.log(v)) / Math.log(2.0);
      }
    }
    return 1 - hp;
  };

  const calculateMetrics = (competenceValues) => {
    //Calculate Humanpotential
    const h = shannonFormula(competenceValues);
    // Calculate Temperature
    const t = competenceValues.length == 0 ? 0 : 120000 / (h * 1000); // Using targetRevenue of 120000
    const r = 2.42; // Example redundancy value
    const i = 1.24; // Example innovation power
    const c =
      competenceValues.reduce((a, b) => a + b, 0) / competenceValues.length;
    // Calculate competencies
    const competencies = competenceValues.length;

    // Calculate specificity (mu)
    let mu = 0;
    if (competencies > 0) {
      if (competencies === 1) {
        mu = 100;
      } else {
        const hMax = 1 + Math.log(competencies) / Math.log(2);
        mu = 100 * (1 - h / hMax);
      }
    }

    return { h, t, r, i, c, competencies, mu };
  };

  return { calculateMetrics };
};
