import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useGetApi, usePatchApi } from "../../hook/useApiHook";

const token = localStorage.getItem("token");

export const fetchUserData = createAsyncThunk(
  "profile/fetchUserData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await useGetApi(
        `${process.env.REACT_APP_API_URL}/users/get_user_data`
      );
      return response.user;
    } catch (error) {
      return rejectWithValue(error.response.data || "Something went wrong");
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "profile/updateUserProfile",
  async ({ id, userData }, { rejectWithValue }) => {
    try {
      const response = await usePatchApi(
        `${process.env.REACT_APP_API_URL}/users/update/${id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadProfilePicture = createAsyncThunk(
  "user/uploadProfilePicture",
  async (file, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("profileImage", file);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/add_picture`,
        {
          method: "PATCH",
          body: formData,
          credentials: "include",
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload image");
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  activeTab: sessionStorage.getItem("activeTab") || "Profil",
  isModalOpen: false,
  userData: {
    username: "",
    email: "",
    phone: "",
    zipcode: "",
    city: "",
    profileImage: "",
    personalInfo: {},
    experience: [],
    skills: [],
    keySkills: [],
    jobPreferences: {}
  },
  loading: false,
  error: null,
  profilePicture: null,
  isLoading: false
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
      sessionStorage.setItem("activeTab", action.payload);
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    updateUserData: (state, action) => {
      state.userData = { ...state.userData, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
        state.error = null;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.userData = { ...state.userData, ...action.payload };
        state.loading = false;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(uploadProfilePicture.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(uploadProfilePicture.fulfilled, (state, action) => {
        state.isLoading = false;
        state.profilePicture = action.payload;
      })
      .addCase(uploadProfilePicture.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});
export const { setActiveTab, setIsModalOpen, updateUserData } =
  profileSlice.actions;
export default profileSlice.reducer;
