import React from "react";

const CircularProgressChart = ({ percentage, width, height }) => {
  const size = Math.min(width, height);
  const strokeWidth = size * 0.13; // Adjusted to maintain proportion
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;
  return (
    <div className="relative" style={{ width, height }}>
    <svg width={width} height={height} viewBox={`0 0 ${size} ${size}`}>
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="transparent"
        stroke="#E5E7EB"
        strokeWidth={strokeWidth}
      />
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        fill="transparent"
        stroke="#317A9A"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        strokeLinecap="round"
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
      />
    </svg>
    <div className="absolute inset-0 flex items-center justify-center">
      <span className="text-sm font-bold text-gray-700" style={{ fontSize: `${size * 0.2}px` }}>
        {percentage}%
      </span>
    </div>
  </div>
  );
};

export default CircularProgressChart;
