import React, { useState, useEffect, useRef } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup
} from "react-simple-maps";
import { scaleOrdinal } from "d3-scale";
import { Plus, Minus, Hand } from "lucide-react";
import Loader from "../../../components/Loader";

// Using Natural Earth Data with different detail levels
const geoUrl =
  "https://raw.githubusercontent.com/nvkelso/natural-earth-vector/master/geojson/ne_110m_admin_0_countries.geojson";
const statesUrl =
  "https://raw.githubusercontent.com/nvkelso/natural-earth-vector/master/geojson/ne_10m_admin_1_states_provinces.geojson";

const colorScale = scaleOrdinal()
  .domain([
    "Hohes Fähigkeitsniveau",
    "Dazwischenliegend Fähigkeitsniveau",
    "Mittleres Fähigkeitsniveau",
    "Schlechtes Fähigkeitsniveau"
  ])
  .range(["#1a2f38", "#3ca0d0", "#d15b46", "#f9a65a"]);

const WorldMap = () => {
  const [tooltipContent, setTooltipContent] = useState(null);
  const [countryColors, setCountryColors] = useState({});
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, top: 0 });
  const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });
  const [isDragging, setIsDragging] = useState(false);
  const [hoveredRegion, setHoveredRegion] = useState(null);
  const [isTooltipHovered, setIsTooltipHovered] = useState(false);
  const [worldData, setWorldData] = useState(null);
  const [statesData, setStatesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const mapRef = useRef(null);
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);
  const [statesByCountry, setStatesByCountry] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch both datasets concurrently
        const [worldResponse, statesResponse] = await Promise.all([
          fetch(geoUrl),
          fetch(statesUrl)
        ]);

        if (!worldResponse.ok) {
          throw new Error(
            `Failed to fetch world data: ${worldResponse.statusText}`
          );
        }
        if (!statesResponse.ok) {
          throw new Error(
            `Failed to fetch states data: ${statesResponse.statusText}`
          );
        }

        const worldJson = await worldResponse.json();
        const statesJson = await statesResponse.json();

        // Process and organize states by country
        const statesMap = statesJson.features.reduce((acc, state) => {
          const countryName = state.properties.admin;
          if (!acc[countryName]) {
            acc[countryName] = [];
          }
          acc[countryName].push({
            name: state.properties.name,
            type: state.properties.type || "State/Province",
            population: state.properties.pop_est,
            region: state.properties.region
          });
          return acc;
        }, {});

        setStatesByCountry(statesMap);
        setWorldData(worldJson);
        setStatesData(statesJson);

        // Generate random colors for countries
        const colors = {};
        worldJson.features.forEach((feature) => {
          colors[feature.properties.NAME] = colorScale(
            [
              "Hohes Fähigkeitsniveau",
              "Dazwischenliegend Fähigkeitsniveau",
              "Mittleres Fähigkeitsniveau",
              "Schlechtes Fähigkeitsniveau"
            ][Math.floor(Math.random() * 4)]
          );
        });
        setCountryColors(colors);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleZoomIn = () => {
    if (position.zoom >= 4) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom * 1.5 }));
  };

  const handleZoomOut = () => {
    if (position.zoom <= 1) return;
    setPosition((pos) => ({ ...pos, zoom: pos.zoom / 1.5 }));
  };

  const handleMoveStart = () => {
    setIsDragging(true);
  };

  const handleMoveEnd = () => {
    setIsDragging(false);
  };

  const getRegionInfo = (geo) => {
    const countryName = geo.properties.NAME;
    const states = statesByCountry[countryName] || [];

    return {
      country: countryName,
      states: states,
      level: countryColors[countryName]
        ? Object.entries(colorScale.domain()).find(
            ([, color]) => color === countryColors[countryName]
          )?.[0]
        : "Unbekannt",
      population: geo.properties.POP_EST?.toLocaleString() || "N/A",
      capital: geo.properties.CAPITAL || "N/A"
    };
  };
  const handleMouseEnter = (geo, event) => {
    if (isDragging) return;

    const regionInfo = getRegionInfo(geo);
    setHoveredRegion(regionInfo.country);
    setTooltipContent(regionInfo);

    // Get the map container's bounding rectangle
    const mapRect = containerRef.current.getBoundingClientRect();

    // Calculate position relative to the map container
    const x = event.clientX - mapRect.left;
    const y = event.clientY - mapRect.top;

    // Set tooltip position, ensuring it stays within the map bounds
    setTooltipPosition({
      left: Math.min(Math.max(x, 200), mapRect.width - 200), // Keep tooltip 200px from edges
      top: Math.max(y - 20, 100) // Position 20px above cursor, minimum 100px from top
    });
  };

  const handleMouseLeave = (event) => {
    const tooltipElement = tooltipRef.current;
    if (tooltipElement) {
      const tooltipRect = tooltipElement.getBoundingClientRect();
      const isMovingToTooltip =
        event.clientX >= tooltipRect.left &&
        event.clientX <= tooltipRect.right &&
        event.clientY >= tooltipRect.top &&
        event.clientY <= tooltipRect.bottom;

      if (isMovingToTooltip) {
        return;
      }
    }

    if (!isTooltipHovered) {
      setTooltipContent(null);
      setHoveredRegion(null);
    }
  };

  const handleTooltipMouseEnter = () => {
    setIsTooltipHovered(true);
  };

  const handleTooltipMouseLeave = () => {
    setIsTooltipHovered(false);
    setTooltipContent(null);
    setHoveredRegion(null);
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-96">
        <div className="text-red-500">Error loading map data: {error}</div>
      </div>
    );
  }

  return (
    <div className="relative" ref={containerRef}>
      <div className="absolute top-4 right-4 z-10 flex flex-col gap-2">
        <button
          onClick={handleZoomIn}
          className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
        >
          <Plus className="w-6 h-6" />
        </button>
        <button
          onClick={handleZoomOut}
          className="p-2 bg-white rounded-full shadow-lg hover:bg-gray-100"
        >
          <Minus className="w-6 h-6" />
        </button>
      </div>

      <div className="absolute top-4 left-4 z-10">
        <Hand
          className={`w-6 h-6 ${
            isDragging ? "text-blue-500" : "text-gray-500"
          }`}
        />
      </div>

      <div className="world-map-container">
        <div className="map-wrapper relative">
          {tooltipContent && (
            <div
              className="absolute inset-0 bg-white/50 z-10 pointer-events-none"
              style={{
                maskImage: hoveredRegion
                  ? "radial-gradient(transparent 0%, black 100%)"
                  : "none",
                WebkitMaskImage: hoveredRegion
                  ? "radial-gradient(transparent 0%, black 100%)"
                  : "none"
              }}
            />
          )}

          <ComposableMap
            projection="geoMercator"
            style={{ width: "100%", height: "535px" }}
            ref={mapRef}
          >
            <ZoomableGroup
              zoom={position.zoom}
              center={position.coordinates}
              onMoveStart={handleMoveStart}
              onMoveEnd={handleMoveEnd}
              maxZoom={4}
            >
              {worldData && (
                <Geographies geography={worldData}>
                  {({ geographies }) =>
                    geographies.map((geo) => {
                      const isHovered = geo.properties.NAME === hoveredRegion;
                      return (
                        <Geography
                          key={geo.rsmKey}
                          geography={geo}
                          fill={countryColors[geo.properties.NAME] || "#F5F4F6"}
                          stroke="#FFFFFF"
                          strokeWidth={0.5}
                          onMouseEnter={(event) => handleMouseEnter(geo, event)}
                          onMouseLeave={handleMouseLeave}
                          style={{
                            default: {
                              outline: "none",
                              opacity: tooltipContent && !isHovered ? 0.3 : 1,
                              filter:
                                tooltipContent && !isHovered
                                  ? "blur(0px)"
                                  : "none",
                              transition: "all 0.3s ease"
                            },
                            hover: {
                              fill: "#F53",
                              outline: "none",
                              opacity: 1,
                              filter: "none",
                              transition: "all 0.3s ease"
                            },
                            pressed: {
                              outline: "none",
                              filter: "none"
                            }
                          }}
                          className={
                            isHovered ? "z-20 relative hover:shadow-lg" : ""
                          }
                        />
                      );
                    })
                  }
                </Geographies>
              )}
            </ZoomableGroup>
          </ComposableMap>
        </div>
        {tooltipContent && !isDragging && (
          <div
            ref={tooltipRef}
            className="tooltip absolute bg-white p-4 rounded-lg shadow-xl z-30 max-w-md transition-all duration-200 ease-in-out"
            style={{
              left: `${tooltipPosition.left}px`,
              top: `${tooltipPosition.top}px`,
              transform: "translate(-50%, -100%)"
            }}
            onMouseEnter={handleTooltipMouseEnter}
            onMouseLeave={handleTooltipMouseLeave}
          >
            <div className="relative">
              <div className="absolute w-4 h-4 bg-white transform rotate-45 left-1/2 -bottom-2 -ml-2"></div>
              <div className="relative bg-white rounded-lg z-10">
                <h3 className="font-bold text-lg">{tooltipContent.country}</h3>
                <p className="text-sm text-gray-600">{tooltipContent.level}</p>

                {tooltipContent.states && tooltipContent.states.length > 0 && (
                  <div className="mt-2">
                    <p className="font-semibold">
                      States/Provinces ({tooltipContent.states.length}):
                    </p>
                    <div className="max-h-40 overflow-y-auto mt-1">
                      {tooltipContent.states.map((state, index) => (
                        <div
                          key={index}
                          className="text-sm py-1 border-b border-gray-100 last:border-0"
                        >
                          <span className="font-medium">{state.name}</span>
                          {state.type && (
                            <span className="text-gray-500 text-xs ml-1">
                              ({state.type})
                            </span>
                          )}
                          {state.region && (
                            <div className="text-xs text-gray-500">
                              Region: {state.region}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="legend mt-4 flex flex-wrap text-sm">
        {colorScale.domain().map((level) => (
          <div key={level} className="flex items-center mr-4 mb-2">
            <div
              style={{ backgroundColor: colorScale(level) }}
              className="w-4 h-4 rounded mr-2"
            />
            <span>{level}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorldMap;
