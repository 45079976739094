export const InputField = ({
  id,
  type,
  placeholder,
  className,
  value,
  onChange,
  name,
  disabled
}) => (
  <input
    id={id}
    type={type}
    name={name}
    placeholder={placeholder}
    className={`w-full p-[10px] border border-[#CCCCCC] font-normal leading-[18px] text-sm rounded-[7px] focus:outline-none focus:outline-none ${className}`}
    value={value}
    onChange={onChange}
    disabled={disabled}
  />
);
