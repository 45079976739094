import CountUp from "../../../components/CountUp";

export const SidebarCard = ({ icon: Icon, label, value }) => (
  <div className="bg-[#083D4E] text-white p-4 rounded-lg flex items-center justify-between">
    <div className="flex items-center space-x-3">
      <div className={`p-3 rounded-full bg-[#ffff]`}>
        <img src={Icon} alt={label} className="w-6 h-6" />
      </div>
      <div>
        <div>{label}</div>
        <div className="font-bold">
          <CountUp targetNumber={value} />
        </div>
      </div>
    </div>
  </div>
);
