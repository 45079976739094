import { useEffect, useRef, useState } from "react";
import { InputField } from "../../../components/InputField";
import UserIcon from "../../../assets/icon/UserIcon.svg";
import ImageIcon from "../../../assets/icon/ImgIcon.svg";
import ReloadIcon from "../../../assets/icon/reloadIcon.svg";
import { ReactComponent as SaveIcon } from "../../../assets/icon/SaveIcon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icon/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserData,
  setIsModalOpen,
  updateUserProfile,
  uploadProfilePicture
} from "../../../redux/slice/profileSlice";

const EditProfileModal = ({ isOpen, onClose, id }) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.profile);

  const [errorMessage, setErrorMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    username: "",
    birthdate: "",
    email: "",
    phone: "",
    zipcode: "",
    location: ""
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (isOpen) {
      setFormData({
        username: userData.username,
        birthdate: userData.birthdate,
        email: userData.email,
        phone: userData.phone,
        zipcode: userData.zipcode,
        location: userData.location
      });
      setSelectedImage(userData.profileImage || null);
    }
  }, [isOpen, userData]);

  const handleImageUploadClick = () => {
    fileInputRef.current.click();
  };
  if (!isOpen) return null;
  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSizeInMB = 2;

      if (!allowedTypes.includes(file.type)) {
        setErrorMessage("Nur JPG, JPEG oder PNG Dateien sind zulässig.");
        return;
      }

      const fileSizeInMB = file.size / 1024 / 1024;
      if (fileSizeInMB > maxSizeInMB) {
        setErrorMessage("Die Datei darf maximal 2 MB groß sein.");
        return;
      }

      setErrorMessage("");
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      try {
        await dispatch(uploadProfilePicture(file)).unwrap();
      } catch (err) {
        setErrorMessage("Fehler beim Hochladen des Bildes.");
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await dispatch(updateUserProfile({ id, userData: formData })).unwrap();
      dispatch(setIsModalOpen(false));
      dispatch(fetchUserData());
    } catch (err) {
      console.log("err: ", err);
    }
  };

  const handleImageReset = () => {
    setSelectedImage(null);
    fileInputRef.current.value = "";
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
      <div className="bg-white rounded-[15px] shadow-lg max-w-[830px] w-[calc(100%-30px)] max-h-[95vh] overflow-auto">
        <div className="flex justify-between items-center px-5 py-[15px] border-b border-[#CCCCCC]">
          <h3 className="text-2xl font-bold">Persönliche Informationen</h3>
        </div>

        <div className="border-b border-[#CCCCCC] p-5 flex sm:flex-row flex-col sm:items-center gap-5">
          <div className="w-[130px] h-[130px] flex items-center">
            <img
              src={selectedImage || UserIcon}
              alt="Profile"
              className="w-full h-full rounded-full"
            />
          </div>
          <div>
            <div className="flex sm:flex-row flex-col sm:items-center gap-5 mb-[10px]">
              <button
                className="bg-[#BF5547] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
                onClick={handleImageUploadClick}
              >
                <img src={ImageIcon} alt="icon" />
                Neues Bild hochladen
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/jpeg, image/png, image/jpg"
                onChange={handleImageChange}
              />
              <button
                className="bg-[#cccccc] text-[#333333] text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
                onClick={handleImageReset}
              >
                <img src={ReloadIcon} alt="icon" />
                Zurücksetzen
              </button>
            </div>
            <p className="text-sm leading-[18px] text-[#333333]">
              Zulässig sind JPG, JPEG oder PNG, maximale Größe 2 MB
            </p>
            <p className="text-sm leading-[18px] text-[#e53d3d]">
              {errorMessage}
            </p>
          </div>
        </div>

        <div className="p-5">
          <div className="grid sm:grid-cols-2 gap-5 mb-5">
            <div>
              <label className="block text-[#333333] sm:text-base text-sm font-medium mb-[10px]">
                Vollständiger Name
              </label>
              <div className="relative">
                <InputField
                  value={formData.username}
                  name="username"
                  type="text"
                  onChange={handleInputChange}
                  placeholder="Geben Sie den vollständigen Namen ein"
                />
              </div>
            </div>
            <div>
              <label className="block text-[#333333] sm:text-base text-sm font-medium mb-[10px]">
                Geburtsdatum
              </label>
              <div className="relative">
                <InputField
                  type="text"
                  name="birthdate"
                  value={formData.birthdate}
                  onChange={handleInputChange}
                  className="w-full p-[10px] border rounded-[7px] border-[#CCCCCC] text-sm font-normal leading-[18px]"
                  placeholder="Geburtsdatum auswählen"
                />
              </div>
            </div>
            <div>
              <label className="block text-[#333333] sm:text-base text-sm font-medium mb-[10px]">
                E-Mail
              </label>
              <div className="relative">
                <InputField
                  type="email"
                  name="email"
                  value={formData.email}
                  className="w-full p-[10px] border rounded-[7px] border-[#CCCCCC] text-sm font-normal leading-[18px]"
                  placeholder="E-Mail schreiben"
                  disabled
                />
              </div>
            </div>
            <div>
              <label className="block text-[#333333] sm:text-base text-sm font-medium mb-[10px]">
                Kontaktnummer
              </label>
              <div className="flex">
                <select className="w-20 border-r-0 border border-gray-300 rounded-l-[7px] bg-[#D9D9D9] focus:outline-none">
                  <option>+40</option>
                  <option>+91</option>
                </select>
                <div className="relative flex-grow">
                  <InputField
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    className="w-full p-[10px] border rounded-none rounded-r-[7px] border-[#CCCCCC] text-sm font-normal leading-[18px]"
                    placeholder="Geben Sie die Kontaktnummer ein"
                  />
                </div>
              </div>
            </div>
            <div>
              <label className="block text-[#333333] sm:text-base text-sm font-medium mb-[10px]">
                Ort
              </label>
              <div className="relative">
                <InputField
                  type="location"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  className="w-full p-[10px] border rounded-[7px] border-[#CCCCCC] text-sm font-normal leading-[18px]"
                  placeholder="Geben Sie den vollständigen Stadt"
                />
              </div>
            </div>
            <div>
              <label className="block text-[#333333] sm:text-base text-sm font-medium mb-[10px]">
                Postleitzahl
              </label>
              <div className="relative">
                <InputField
                  type="zipcode"
                  name="zipcode"
                  value={formData.zipcode}
                  onChange={handleInputChange}
                  className="w-full p-[10px] border rounded-[7px] border-[#CCCCCC] text-sm font-normal leading-[18px]"
                  placeholder="Geben Sie den vollständigen PLZ"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end sm:gap-[20px] gap-[10px]  mt-5">
            <button
              className="text-[#333333] text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
              onClick={onClose}
            >
              <CloseIcon />
              Stornieren
            </button>
            <button
              className="bg-[#317A9A] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
              onClick={handleSubmit}
            >
              <SaveIcon />
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfileModal;
