import React, { useState } from "react";
import MultiSelect from "../../../components/MultiSelect";
import DownArrow from "../../../assets/icon/DownArrow.svg";
import CustomApexBarChart from "../../../components/Charts/CustomApexBarChart";

const EmployeeCompetenciesDashboard = () => {
  const [knowledge, setKnowledge] = useState("N1 - Grundkenntnisse");
  const [selectedEmployment, setSelectedEmployment] = useState([]);

  const employees = [
    {
      name: "Moritz Krog",
      competencies: [10, 3, 5, 6, 7, 5, 4],
      position: "Projektmanagement",
      cost: 4100,
    },
    {
      name: "Sebastian Strauch",
      competencies: [3, 2, 4, 5, 6, 4, 3],
      position: "Senior Full Stack Entwickler",
      cost: 3600,
    },
  ];
  const employmentOptions = [
    { value: "permanent", label: "Dauerhaft" },
    { value: "internship", label: "Praktikum" },
    { value: "part-time", label: "Teilzeit" },
  ];

  return (
    <>
      <div className="mb-5 flex md:flex-row flex-col gap-5">
        <div className="lg:w-2/3 md:w-1/2 w-full">
          <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium leading-[19px]">
            Fähigkeiten
          </label>
          <MultiSelect
            options={employmentOptions}
            selectedOptions={selectedEmployment}
            onChange={setSelectedEmployment}
          />
        </div>
        <div className="lg:w-1/3 md:w-1/2 w-full">
          <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium leading-[19px]">
            Wissen
          </label>
          <div className="relative">
            <select
              className="border border-[#CCCCCC] rounded-lg px-4 py-3 w-full appearance-none"
              value={knowledge}
              onChange={(e) => setKnowledge(e.target.value)}
            >
              <option>N1 - Grundkenntnisse</option>
            </select>
            <img
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
              src={DownArrow}
              alt="Arrow"
              size={20}
            />
          </div>
        </div>
      </div>
      <div className="overflow-x-auto bg-white rounded-[15px]">
        <table className="table-fixed w-full border-collapse">
          <thead>
            <tr className="text-left border-b border-gray-200">
              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[150px]">
                Name
              </th>
              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[150px]">
                Kompetenzen
              </th>
              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[190px]">
                Arbeitsplatz
              </th>
              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[150px]">
                Personalkosten
              </th>
              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[180px]">
                Aktionen
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {employees.map((employee, index) => (
              <tr key={index} className="border-b border-gray-200">
                <td className="text-[#333333] font-normal text-sm px-5 py-[15px]">
                  {employee.name}
                </td>
                <td className="px-5 py-[15px] text-center align-top">
                  <CustomApexBarChart
                    colors={["#317A9A", "#E5E7EB"]}
                    competences={[
                      { name: "JavaScript" },
                      { name: "React" },
                      { name: "Node.js" },
                      { name: "CSS" },
                      { name: "HTML" },
                      { name: "Shopify" },
                      { name: "Java" },
                    ]}
                    series={[
                      {
                        name: "Filled",
                        data: [50, 60, 66, 55, 60, 88, 65],
                      },
                      {
                        name: "Remaining",
                        data: [50, 40, 34, 45, 40, 12, 35],
                      },
                    ]}
                  />
                </td>
                <td className="text-[#333333] font-normal text-sm px-5 py-[15px]">
                  {employee.position}
                </td>
                <td className="px-6 py-4">
                  <span className="bg-[#009951] rounded-[5px] text-[#333333] font-normal text-sm px-[5px] py-[4px] leading-[18px]">
                    {employee.cost}
                  </span>
                </td>
                <td className="px-6 py-4">
                  <div className="relative">
                    <select className="border border-[#CCCCCC] rounded-lg px-4 py-2 w-full appearance-none">
                      <option>Aktionen</option>
                    </select>
                    <img
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
                      src={DownArrow}
                      alt="Arrow"
                      size={20}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default EmployeeCompetenciesDashboard;
