import React, { useState, useCallback, useRef, useEffect } from "react";
import { ResponsiveBar } from "@nivo/bar";

const MicroChart = ({
  graphData,
  onSkillUpdate,
  barColor = "#F0884D",
  circleColor = "#FF8C00",
  tooltipBgColor = "#AA4A44",
  barBorderRadius = 20,
}) => {
  const [data, setData] = useState(graphData);
  const [draggingIndex, setDraggingIndex] = useState(null);
  const [startY, setStartY] = useState(null);
  const [startPercentage, setStartPercentage] = useState(null);
  const [tooltip, setTooltip] = useState({
    show: false,
    content: "",
    x: 0,
    y: 0,
    width: 0,
  });

  useEffect(() => {
    setData(graphData);
  }, [graphData]);

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (draggingIndex !== null) {
      const handleGlobalMouseMove = (event) => {
        if (startY !== null && startPercentage !== null) {
          const chartContainer = document.getElementById("chart-container");
          const { height } = chartContainer.getBoundingClientRect();
          const deltaY = startY - event.clientY;
          const deltaPercentage = (deltaY / height) * 100;
          const newPercentage = Math.max(
            0,
            Math.min(100, startPercentage + deltaPercentage)
          );
          setData((prevData) =>
            prevData.map((item, index) =>
              index === draggingIndex
                ? { ...item, percentage: Math.round(newPercentage) }
                : item
            )
          );
        }
      };

      const handleGlobalMouseUp = async () => {
        if (draggingIndex !== null) {
          const updatedSkill = data[draggingIndex];
          try {
            await onSkillUpdate(updatedSkill);
          } catch (error) {
            console.error("Failed to update skill:", error);
            setData(graphData);
          }
        }
        setDraggingIndex(null);
        setStartY(null);
        setStartPercentage(null);
      };

      // Add global event listeners
      document.addEventListener("mousemove", handleGlobalMouseMove);
      document.addEventListener("mouseup", handleGlobalMouseUp);

      // Cleanup function
      return () => {
        document.removeEventListener("mousemove", handleGlobalMouseMove);
        document.removeEventListener("mouseup", handleGlobalMouseUp);
      };
    }
  }, [draggingIndex, startY, startPercentage, data, onSkillUpdate, graphData]);

  const handleMouseDown = (index, event) => {
    event.preventDefault(); // Prevent text selection
    setDraggingIndex(index);
    setStartY(event.clientY);
    setStartPercentage(data[index].percentage);
  };

  // const handleTooltip = useCallback((bar) => {
  //   console.log('bar: ', bar);
  //   const scale = bar?.data?.data?.scale || "N/A";
  //   const content = `${scale}`;
  //   const estimatedWidth =
  //     Math.max(...content.split("\n").map((line) => line.length)) * 8 + 20;
  //   setTooltip({
  //     show: true,
  //     content,
  //     x: bar.x + bar.width / 2,
  //     y: bar.y - 10,
  //     width: estimatedWidth,
  //   });
  // }, []);

  const handleTooltip = useCallback((bar) => {
    let scale = bar?.data?.data?.scale;

    // If scale is N/A or null, calculate it from percentage
    if (!scale || scale === "N/A") {
      const percentage = bar?.data?.data?.percentage || 0;

      if (percentage >= 80) {
        scale = "N5 - Experte"; // For 80% and above
      } else if (percentage >= 50) {
        scale = "N4 - Umfassend"; // For 50% to 79.9%
      } else if (percentage >= 40) {
        scale = "N3 - Sicher"; // For 40% to 49.9%
      } else if (percentage >= 30) {
        scale = "N2 - Anwender"; // For 30% to 39.9%
      } else if (percentage >= 20) {
        scale = "N1 - Grundkenntnisse"; // For 20% to 29.9%
      } else {
        scale = "N0 - Keine Kenntnisse"; // For below 20%
      }
    }

    const content = `${scale}`;
    const estimatedWidth =
      Math.max(...content.split("\n").map((line) => line.length)) * 8 + 20;
    setTooltip({
      show: true,
      content,
      x: bar.x + bar.width / 2,
      y: bar.y - 10,
      width: estimatedWidth,
    });
  }, []);

  const handleTooltipLeave = () => {
    setTooltip({ show: false, content: "", x: 0, y: 0, width: 0 });
  };

  useEffect(() => {
    if (tooltip.show && tooltipRef.current) {
      const tooltipElement = tooltipRef.current;
      const chartContainer = document.getElementById("chart-container");
      const containerRect = chartContainer.getBoundingClientRect();
      const tooltipRect = tooltipElement.getBoundingClientRect();
      if (tooltipRect.right > containerRect.right) {
        setTooltip((prev) => ({
          ...prev,
          x: containerRect.right - tooltipRect.width / 2 - 10,
        }));
      } else if (tooltipRect.left < containerRect.left) {
        setTooltip((prev) => ({
          ...prev,
          x: containerRect.left + tooltipRect.width / 2 + 10,
        }));
      }
    }
  }, [tooltip]);

  return (
    <div
      id="chart-container"
      style={{
        height: "500px",
        position: "relative",
        userSelect: "none",
        cursor: draggingIndex !== null ? "ns-resize" : "default",
      }}
      onMouseLeave={handleTooltipLeave}
    >
      <ResponsiveBar
        data={data}
        keys={["percentage"]}
        indexBy="skill"
        margin={{ top: 50, right: 50, bottom: 100, left: 50 }}
        padding={0.3}
        valueScale={{ type: "linear", min: 0, max: 100 }}
        indexScale={{ type: "band", round: true }}
        colors={() => barColor}
        borderRadius={barBorderRadius}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 45,
          legendPosition: "middle",
          legendOffset: 80,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: "middle",
          legendOffset: -40,
          format: (value) => `${value}%`,
          tickValues: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        }}
        enableGridX={false}
        enableGridY={true}
        enableLabel={false}
        isInteractive={true}
        animate={false}
        motionStiffness={90}
        motionDamping={15}
        layers={[
          "grid",
          "axes",
          "bars",
          ({ bars }) =>
            bars.map((bar) => (
              <g key={bar.key}>
                <rect
                  x={bar.x}
                  y={bar.y}
                  width={bar.width}
                  height={bar.height}
                  fill={bar.color}
                  rx={20}
                  ry={20}
                  style={{ cursor: "pointer" }}
                  onMouseEnter={() => handleTooltip(bar)}
                  onMouseLeave={handleTooltipLeave}
                />
                <circle
                  cx={bar.x + bar.width / 2}
                  cy={bar.y}
                  r={8}
                  fill="white"
                  stroke={circleColor}
                  strokeWidth={2}
                  style={{ cursor: "ns-resize" }}
                  onMouseDown={(event) => handleMouseDown(bar.index, event)}
                />
              </g>
            )),
          () =>
            tooltip.show && (
              <g ref={tooltipRef}>
                <rect
                  x={tooltip.x - tooltip.width / 2}
                  y={tooltip.y - 30}
                  width={tooltip.width}
                  height={30}
                  fill={tooltipBgColor}
                  rx={10}
                  ry={10}
                />
                <text
                  x={tooltip.x}
                  y={tooltip.y - 13}
                  textAnchor="middle"
                  fill="white"
                  fontSize="12px"
                >
                  {tooltip.content}
                </text>
              </g>
            ),
        ]}
      />
    </div>
  );
};

export default MicroChart;
