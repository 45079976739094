import React from "react";

const ColorBar = ({ value }) => {
  return (
    <div className="w-full">
      <div className="relative h-2 rounded-full overflow-hidden">
        {/* Gradient background */}
        <div
          className="absolute inset-0 w-full h-full"
          style={{
            background:
              "linear-gradient(90deg, #FF0000 0%, #FFFF00 20%, #00FF00 40%, #00FFFF 60%, #0000FF 80%, #FF00FF 100%)",
          }}
        />
        {/* White overlay that adjusts based on value */}
        <div
          className="absolute top-0 right-0 h-full bg-white"
          style={{ width: `${100 - value}%` }}
        />
      </div>
    </div>
  );
};

export default ColorBar;
