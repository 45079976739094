import React from "react";
import LocationIcon from "../../../../assets/icon/LocationIcon.svg";
import { ReactComponent as BtnArrowRight } from "../../../../assets/icon/BtnArrowRight.svg";
import CompaneyImage from "../../../../assets/images/companyLogoImage.png";
import BagIcon from "../../../../assets/icon/Brifcase_Black.svg";
import GraduationIcon from "../../../../assets/icon/Graduation_Black.svg";
import WatchIcon from "../../../../assets/icon/Watch_Black.svg";

const JobCard = ({
  title,
  company,
  location,
  openings,
  type,
  experience,
  salary,
}) => (
  <div className="bg-[#F2F6FA] p-5 rounded-[15px]">
    <div className="flex items-center gap-5 mb-5">
      <div className="w-20 h-20 rounded-full">
        <img src={CompaneyImage} alt="icon" />
      </div>
      <div className="w-[calc(100%-100px)]">
        <h3 className="lg:text-xl md:text-lg text-base font-bold text-[#333333]">{title}</h3>
        <p className="text-sm font-normal text-[#333333] leading-[18px]">{company}</p>
      </div>
    </div>
    <div className="flex items-center flex-wrap mb-5 gap-5">
      <div className="flex items-center gap-[10px] px-5 py-[5px] rounded-full text-sm font-normal text-[#333333] bg-white">
        <img src={LocationIcon} alt="icon" height={16} width={14} />
        {location}
      </div>
      <div className="flex items-center gap-[10px] px-5 py-[5px] rounded-full text-sm font-normal text-[#333333] bg-white">
      <img src={BagIcon} alt="icon" height={16} width={14} />
        {openings} Eröffnungen
      </div>
      <div className="flex items-center gap-[10px] px-5 py-[5px] rounded-full text-sm font-normal text-[#333333] bg-white">
        <img src={GraduationIcon} alt="icon" height={16} width={14} />
        {type}
      </div>
      <div className="flex items-center gap-[10px] px-5 py-[5px] rounded-full text-sm font-normal text-[#333333] bg-white">
      <img src={WatchIcon} alt="icon" height={16} width={14} />
        Mindestens {experience}
      </div>
    </div>
    <div className="md:text-base text-sm text-[#333333] font-semibold mb-5">{salary}</div>
    <button className="bg-[#317A9A] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit">
      Bewerben Sie sich jetzt <BtnArrowRight />
    </button>
  </div>
);

const JobListings = () => {
  const jobs = [
    {
      title: "HTML-Entwickler - Fresher",
      company: "Spacech Technical Solutions",
      location: "Berlin",
      openings: 13,
      type: "Absolvent",
      experience: "2 Jahre",
      salary: "€40,000 - €42,000 PA",
    },
    {
      title: "React-Hauptentwickler",
      company: "Infratech PVT LTD",
      location: "Munich",
      openings: 13,
      type: "Absolvent",
      experience: "3 Jahre",
      salary: "€40,000 - €42,000 PA",
    },
    {
      title: "HTML-Entwickler - Fresher",
      company: "Spacech Technical Solutions",
      location: "Berlin",
      openings: 13,
      type: "Absolvent",
      experience: "2 Jahre",
      salary: "€40,000 - €42,000 PA",
    },
    {
      title: "React-Hauptentwickler",
      company: "Infratech PVT LTD",
      location: "Munich",
      openings: 13,
      type: "Absolvent",
      experience: "3 Jahre",
      salary: "€40,000 - €42,000 PA",
    },
    {
      title: "React-Hauptentwickler",
      company: "Infratech PVT LTD",
      location: "Munich",
      openings: 13,
      type: "Absolvent",
      experience: "3 Jahre",
      salary: "€40,000 - €42,000 PA",
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center px-5 py-[15px] border-b border-[#CCCCCC]">
        <h3 className="text-lg font-bold">Besuchte Jobs</h3>
      </div>
      <div className="p-5 grid lg:grid-cols-2 gap-5">
        {jobs.map((job, index) => (
          <JobCard key={index} {...job} />
        ))}
      </div>
    </>
  );
};

export default JobListings;
