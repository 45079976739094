import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "../../../../assets/icon/EditIcon.svg";
import ExperienceEditForm from "../../EditExperienceModel";
import EditJobModel from "../../EditJobModel";
import KeyCompetenciesEditor from "../../EditKeyCompetencies";
import {
  fetchSelectedSkills,
  fetchSkills,
  rateSkill,
} from "../../../../redux/slice/skillSlice";
import { useDispatch, useSelector } from "react-redux";
import EditCompetencies from "../../EditCompetencies";
import MicroChart from "../../../../components/Charts/MicroChart";
import toast from "react-hot-toast";
import { InfoIcon } from "lucide-react";
import CircularProgressChart from "../../../../components/Charts/CircularProgressChart";
import ColorBar from "../../../../components/Charts/ColorBarGraph";
import { fetchVacancies } from "../../../../redux/slice/vacanciesSlice";
import { useHumaticsCalculations } from "../../../../hook/useHumaticsCalculations";
import CountUp from "../../../../components/CountUp";

const Profile = ({ userData }) => {
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.skills);

  const { vacancies } = useSelector((state) => state.vacancies);
  const { calculateMetrics } = useHumaticsCalculations();

  useEffect(() => {
    dispatch(fetchVacancies());
  }, [dispatch]);

  const averageMetrics = vacancies?.reduce(
    (acc, position) => {
      const metrics = calculateMetrics(
        position.competences.map((c) => c.value)
      );
      return {
        h: metrics.h,
        t: metrics.t,
        r: metrics.r,
        i: metrics.i,
        c: metrics.c,
        competencies: metrics.competencies,
        mu: metrics.mu,
      };
    },
    { h: 0, t: 0, r: 0, i: 0, c: 0, mu: 0 }
  );

  const selectedSkills = useSelector(
    (state) => state?.skills?.selectedSkill?.skills
  );

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`);
    }
  }, [error]);

  const [localSelectedSkills, setLocalSelectedSkills] = useState([]);
  const [editExperienceModalOpen, setEditExperienceModalOpen] = useState(false);
  const [editJobModelIsOpen, setEditJobModelIsOpen] = useState(false);
  const [editEditKeyCompetencies, setEditKeyCompetencies] = useState(false);
  const [competenciesModalOpen, setCompetenciesModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchSelectedSkills());
  }, [dispatch]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchSkills());
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (selectedSkills) {
      setLocalSelectedSkills(selectedSkills);
    }
  }, [selectedSkills]);

  const handleSkillsChange = (updatedSkills) => {
    setLocalSelectedSkills(updatedSkills);
  };

  const handleDataUpdate = useCallback(async () => {
    try {
      await Promise.all([dispatch(fetchSelectedSkills()).unwrap()]);
    } catch (error) {
      toast.error("Failed to fetch updated skills:", error);
    }
  }, [dispatch]);

  const handleSkillUpdate = useCallback(
    async (updatedSkill) => {
      try {
        await dispatch(
          rateSkill({
            skillUuid: updatedSkill.skillUuid,
            skillName: updatedSkill.skill,
            skillDescription: updatedSkill.skillDescription || "",
            percentage: updatedSkill.percentage,
            removeSkill: false,
          })
        ).unwrap();

        await handleDataUpdate();
      } catch (error) {
        console.error("Failed to update skill:", error);
      }
    },
    [dispatch, handleDataUpdate]
  );

  const barGraphData = localSelectedSkills?.map((skill) => ({
    skill: skill?.name,
    percentage: skill?.percentage,
    skillUuid: skill?.skillUuid,
    skillDescription: skill?.description,
    scale: skill?.scale?.name,
  }));

  return (
    <>
      <div className="grid lg:grid-cols-2 gap-5">
        <div>
          <div className="flex justify-between items-center px-5 py-[15px] border-b border-[#CCCCCC]">
            <h3 className="text-lg font-semibold">Persönliche Informationen</h3>
            {/* <img src={EditIcon} alt="editicon" height={24} width={24} /> */}
          </div>
          <div className="p-5 grid gap-5">
            <div className="flex md:items-center md:flex-row flex-col md:gap-3">
              <div className="py-[5px] text-[#333333] w-[140px] font-medium text-base">
                Name
              </div>
              <div className="py-[5px] text-[#333333] w-[calc(100%-140px)] font-normal text-sm">
                {userData?.username}
              </div>
            </div>
            <div className="flex md:items-center md:flex-row flex-col md:gap-3">
              <div className="py-[5px] text-[#333333] w-[140px] font-medium text-base">
                Geburtsdatum
              </div>
              <div className="py-[5px] text-[#333333] w-[calc(100%-140px)] font-normal text-sm">
                {userData?.birthdate}
              </div>
            </div>
            <div className="flex md:items-center md:flex-row flex-col md:gap-3">
              <div className="py-[5px] text-[#333333] w-[140px] font-medium text-base">
                E-Mail
              </div>
              <div className="py-[5px] text-[#333333] w-[calc(100%-140px)] font-normal text-sm">
                {userData?.email}
              </div>
            </div>
            <div className="flex md:items-center md:flex-row flex-col md:gap-3">
              <div className="py-[5px] text-[#333333] w-[140px] font-medium text-base">
                Kontaktnummer
              </div>
              <div className="py-[5px] text-[#333333] w-[calc(100%-140px)] font-normal text-sm">
                {userData?.phone}
              </div>
            </div>
            <div className="flex md:items-center md:flex-row flex-col md:gap-3">
              <div className="py-[5px] text-[#333333] w-[140px] font-medium text-base">
                Ort
              </div>
              <div className="py-[5px] text-[#333333] w-[calc(100%-140px)] font-normal text-sm">
                {userData?.location}
              </div>
            </div>
            <div className="flex md:items-center md:flex-row flex-col md:gap-3">
              <div className="py-[5px] text-[#333333] w-[140px] font-medium text-base">
                Postleitzahl
              </div>
              <div className="py-[5px] text-[#333333] w-[calc(100%-140px)] font-normal text-sm">
                {userData?.zipcode}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center px-5 py-[15px] border-b border-[#CCCCCC]">
            <h3 className="text-lg font-semibold">Erfahrung</h3>
            <button onClick={() => setEditExperienceModalOpen(true)}>
              <img src={EditIcon} alt="editicon" height={24} width={24} />
            </button>
          </div>
          {userData?.experience.length > 0 && (
            <>
              <div className="p-5 grid gap-5">
                {userData?.experience?.map((exp, index) => (
                  <div
                    className="flex md:items-start md:flex-row flex-col md:gap-5"
                    key={index}
                  >
                    <div className="font-medium md:text-base text-sm text-[#333333] md:w-[190px] w-full">
                      {exp?.startdate} bis {exp?.enddate}
                    </div>
                    <div className="grid gap-[10px] md:w-[calc(100%-190px)] w-full">
                      <h4 className="font-medium md:text-base text-sm text-[#333333]">
                        {exp?.company}
                      </h4>
                      <div className="text-sm text-[#333333] font-normal">
                        {exp?.position}
                      </div>
                      <div className="text-sm text-[#333333] font-normal">
                        {exp?.location}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <ExperienceEditForm
                isOpen={editExperienceModalOpen}
                onClose={() => setEditExperienceModalOpen(false)}
              />
            </>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
        <div>
          <div className="flex justify-between items-center px-2 sm:px-5 py-2 sm:py-[15px] border-b border-[#CCCCCC]">
            <h3 className="text-base sm:text-lg font-semibold">Kompetenzen</h3>
            <button onClick={() => setCompetenciesModalOpen(true)}>
              <img src={EditIcon} alt="editicon" height={16} width={20} />
            </button>
          </div>
          <div className="max-w-full sm:max-w-4xl mx-auto p-4 sm:p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
              {/* First Card */}
              <div className="bg-[#F2F6FA] p-4 rounded-[15px]">
                <div className="flex items-center mb-2">
                  <h2 className="text-base sm:text-lg font-semibold">
                    Humanpotential
                  </h2>
                  <InfoIcon className="w-5 h-5 text-gray-500 ml-2" />
                </div>
                <div className="text-3xl sm:text-4xl font-bold">
                  <CountUp targetNumber={averageMetrics.h.toFixed(2)} />
                </div>
                <div className="text-sm text-gray-600">Soll: 4,13</div>
              </div>

              {/* Second Card */}
              <div className="bg-[#F2F6FA] p-4 rounded-[15px]">
                <div className="flex items-center  mb-2">
                  <h2 className="text-base sm:text-lg font-semibold">
                    Engagement Faktor
                  </h2>
                  <InfoIcon className="w-5 h-5 text-gray-500 ml-2" />
                </div>
                <div className="text-3xl sm:text-4xl font-bold">1,01</div>
              </div>

              {/* Third Card */}
              <div className="bg-[#F2F6FA] p-4 rounded-[15px]">
                <div className="flex items-center  mb-2">
                  <h2 className="text-base sm:text-lg font-semibold">
                    Spezifität
                  </h2>
                  <InfoIcon className="w-5 h-5 text-gray-500 ml-2" />
                </div>
                <div className="text-3xl sm:text-4xl font-bold">
                  {averageMetrics.mu.toFixed(2)}
                </div>
                <div className="text-sm text-gray-600">Soll: 0,369</div>
              </div>

              {/* Fourth Card */}
              <div className="bg-[#F2F6FA] p-4 rounded-[15px]">
                <div className="flex items-center  mb-2">
                  <h2 className="text-base sm:text-lg font-semibold">
                    Kompetenzabdeckungsgrad
                  </h2>
                  <InfoIcon className="w-5 h-5 text-gray-500 ml-2" />
                </div>
                <div>
                  <CircularProgressChart
                    percentage={70}
                    height={60}
                    width={60}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4 bg-[#F2F6FA] p-4 rounded-[15px]">
              <div className="flex items-center  mb-2">
                <h2 className="text-base sm:text-lg font-semibold">
                  Ökonomische Temperatur
                </h2>
                <InfoIcon className="w-5 h-5 text-gray-500 ml-2" />
              </div>
              <ColorBar value={averageMetrics.t.toFixed(2)} />
              <div className="flex items-center gap-2.5 mb-2 mt-2.5">
                <h3 className="text-sm font-medium text-gray-500">
                  Ökonomische Temperatur
                </h3>
                <InfoIcon className="w-4 h-4 text-gray-400" />
              </div>
              <p className="text-xl sm:text-2xl font-bold mb-2">
                <CountUp targetNumber={averageMetrics.t.toFixed(2)} />
              </p>
            </div>
          </div>

          <div className="chart-container">
            {barGraphData?.length > 0 ? (
              <MicroChart
                graphData={barGraphData}
                onSkillUpdate={handleSkillUpdate}
              />
            ) : (
              <div className="m-5 text-center text-gray-700 bg-[#F2F6FA] p-4 rounded-[15px]">
                No skills data is available. Please add some skills.
              </div>
            )}
          </div>
        </div>

        {/* Right Column */}
        <div>
          <div className="flex justify-between items-center px-2 sm:px-5 py-2 sm:py-[15px] border-b border-[#CCCCCC]">
            <h3 className="text-base sm:text-lg font-semibold">
              Berufspräferenz
            </h3>
            <button onClick={() => setEditJobModelIsOpen(true)}>
              <img src={EditIcon} alt="editicon" height={24} width={24} />
            </button>
            <EditJobModel
              isOpen={editJobModelIsOpen}
              onClose={() => setEditJobModelIsOpen(false)}
            />
          </div>

          {/* Job Preferences */}
          <div className="p-4 sm:p-5 grid gap-4 sm:gap-5">
            <div>
              <h4 className="font-medium text-sm sm:text-base text-[#333333] mb-[10px]">
                Jobkategorie
              </h4>
              <div className="flex flex-wrap gap-2">
                {userData?.jobPreferences?.categories?.map(
                  (category, index) => (
                    <span
                      key={index}
                      className="px-4 py-[5px] rounded-full text-sm text-[#333333] bg-[#F2F6FA]"
                    >
                      {category}
                    </span>
                  )
                )}
              </div>
            </div>

            <div>
              <h4 className="font-medium text-sm sm:text-base text-[#333333] mb-[10px]">
                Art der Beschäftigung
              </h4>
              <div className="flex flex-wrap gap-2">
                {userData?.jobPreferences?.employmentTypes?.map(
                  (type, index) => (
                    <span
                      key={index}
                      className="px-4 py-[5px] rounded-full text-sm text-[#333333] bg-[#F2F6FA]"
                    >
                      {type}
                    </span>
                  )
                )}
              </div>
            </div>

            <div>
              <h4 className="font-medium text-sm sm:text-base text-[#333333] mb-[10px]">
                Erwartetes Gehalt
              </h4>
              <span className="px-4 py-[5px] rounded-full text-sm text-[#333333] bg-[#F2F6FA]">
                {userData?.jobPreferences?.expectedSalary}
              </span>
            </div>

            <div>
              <h4 className="font-medium text-sm sm:text-base text-[#333333] mb-[10px]">
                Bevorzugter Standort
              </h4>
              <div className="flex flex-wrap gap-2">
                {userData?.jobPreferences?.preferredLocations?.map(
                  (location, index) => (
                    <span
                      key={index}
                      className="px-4 py-[5px] rounded-full text-sm text-[#333333] bg-[#F2F6FA]"
                    >
                      {location}
                    </span>
                  )
                )}
              </div>
            </div>

            <div className="mt-4">
              <EditCompetencies
                isOpen={competenciesModalOpen}
                onClose={() => setCompetenciesModalOpen(false)}
                onSkillsChange={handleSkillsChange}
              />
              <div className="flex justify-between items-center px-2 sm:px-5 py-2 sm:py-[15px] border-b border-[#CCCCCC]">
                <h3 className="text-base sm:text-lg font-semibold">
                  Schlüsselkompetenzen
                </h3>
                <button onClick={() => setEditKeyCompetencies(true)}>
                  <img src={EditIcon} alt="editicon" height={24} width={24} />
                </button>
              </div>
              <KeyCompetenciesEditor
                isOpen={editEditKeyCompetencies}
                onClose={() => setEditKeyCompetencies(false)}
              />

              <div className="p-4 sm:p-5 flex flex-wrap gap-2">
                {Array.isArray(userData?.keySkills?.skills)
                  ? userData?.keySkills?.skills?.map((skill, index) => (
                      <span
                        key={index}
                        className="px-4 py-[5px] rounded-full text-sm text-[#333333] bg-[#F2F6FA]"
                      >
                        {skill}
                      </span>
                    ))
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
