import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useGetApi, usePatchApi } from "../../hook/useApiHook";

const userId = localStorage.getItem("userId");

// Async thunk for fetching skills
export const fetchSkills = createAsyncThunk(
  "skills/fetchSkills",
  async (_, { rejectWithValue }) => {
    try {
      const response = await useGetApi(
        `${process.env.REACT_APP_API_URL}/users/skills`
      );
      return response;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(error || "Failed to fetch user data");
    }
  }
);

export const rateSkill = createAsyncThunk(
  "skills/rateSkill",
  async (
    { skillUuid, skillName, skillDescription, removeSkill, percentage },
    { rejectWithValue }
  ) => {
    try {
      const response = await usePatchApi(
        `${process.env.REACT_APP_API_URL}/users/rate_skill`,
        {
          userId,
          skillUuid,
          skillName,
          skillDescription,
          removeSkill,
          percentage,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Failed to rate skill");
    }
  }
);

export const fetchSelectedSkills = createAsyncThunk(
  "skills/fetchSelectedSkills",
  async (_, { rejectWithValue }) => {
    try {
      const response = await useGetApi(
        `${process.env.REACT_APP_API_URL}/users/get_skills`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  skills: [],
  selectedSkill: [],
  status: "idle",
  loading: false,
  error: null,
};

const skillsSlice = createSlice({
  name: "skills",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSkills.pending, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      })
      .addCase(fetchSkills.fulfilled, (state, action) => {
        state.loading = false;
        state.skills = action.payload;
      })
      .addCase(fetchSkills.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(rateSkill.pending, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      })
      .addCase(rateSkill.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.skills.findIndex(
          (skill) => skill.name === action?.payload?.skillName
        );
        if (index !== -1) {
          state.skills[index] = { ...state.skills[index], ...action.payload };
        }
      })
      .addCase(rateSkill.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSelectedSkills.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedSkill = action.payload;
      })
      .addCase(fetchSelectedSkills.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSelectedSkills.pending, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      });
  },
});

export default skillsSlice.reducer;
