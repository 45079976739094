import { useState, useRef, useEffect } from "react";
import UserIcon from "../../assets/icon/UserIcon.svg";

const UserMenu = ({ username, menuItems, onGlobeClick, profilePic }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={menuRef}>
      <div className="flex items-center space-x-4">
        <button
          className="rounded-full bg-gray-700 text-white w-10 h-10 overflow-hidden flex items-center justify-center"
          onClick={toggleMenu}
        >
          <img
            src={profilePic || UserIcon}
            alt="User profile"
            className="w-full h-full object-cover"
          />
        </button>
      </div>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
          {username && (
            <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200">
              {username}
            </div>
          )}
          {menuItems.map((item, index) => (
            <a
              key={index}
              href={item.href}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
              onClick={(e) => {
                if (item.onClick) {
                  e.preventDefault();
                  item.onClick();
                }
                setIsOpen(false);
              }}
            >
              {item.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserMenu;
