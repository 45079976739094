import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { usePostApi } from "../../hook/useApiHook";

export const STORAGE_KEYS = {
  TOKEN: 'token',
  USER_ID: 'userId',
  ACTIVE_TAB: 'activeTab',
  USER_PREFERENCES: 'userPreferences',
  REMEMBER_ME: 'rememberMe',
  LAST_ROUTE: 'lastRoute'
};

const clearAllStorage = () => {
  Object.values(STORAGE_KEYS).forEach(key => {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  });
};

export const loginUser = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await usePostApi(
        `${process.env.REACT_APP_API_URL}/users/login`,
        credentials
      );
      
      // Store essential auth data
      localStorage.setItem(STORAGE_KEYS.TOKEN, response.data.token);
      localStorage.setItem(STORAGE_KEYS.USER_ID, response.data.user.id);
      
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.response?.data?.message || "Login failed",
        status: error.response?.status,
        timestamp: new Date().toISOString()
      });
    }
  }
);

// Enhanced logout thunk with complete cleanup
export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { dispatch }) => {
    try {
      clearAllStorage();
      dispatch(resetUserState());
      return true;
    } catch (error) {
      console.error("Logout error:", error);
      clearAllStorage();
      dispatch(resetUserState());
      return true;
    }
  }
);

const initialState = {
  user: null,
  token: null,
  loading: false,
  error: null,
  lastLogin: null,
  loginAttempts: 0
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetUserState: (state) => {
      return initialState;
    },
    clearError: (state) => {
      state.error = null;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.loading = false;
      state.error = null;
      state.lastLogin = null;
      state.loginAttempts = 0;
      
      clearAllStorage();
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.lastLogin = new Date().toISOString();
        state.loginAttempts = 0;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.loginAttempts += 1;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        return initialState;
      });
  }
});

export const { resetUserState, clearError, logout } = authSlice.actions;
export default authSlice.reducer;