import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSelectedSkills,
  fetchSkills,
  rateSkill,
} from "../../../redux/slice/skillSlice";
import { Search, X } from "lucide-react";
import { truncateText } from "../../../utils/truncateText";
import { InputField } from "../../../components/InputField";

const EditCompetencies = ({ isOpen, onClose, onSkillsChange }) => {
  const dispatch = useDispatch();
  const { skills } = useSelector((state) => state?.skills);
  const selectedSkills = useSelector(
    (state) => state?.skills?.selectedSkill?.skills
  );

  const [isVisible, setIsVisible] = useState(false);
  const [localSelectedSkills, setLocalSelectedSkills] = useState([]);
  const [maxDescriptionLength] = useState(50);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredSkills = skills?.filter((skill) =>
    skill?.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
      dispatch(fetchSkills());
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen, dispatch]);

  useEffect(() => {
    dispatch(fetchSelectedSkills());
  }, [dispatch]);

  useEffect(() => {
    if (selectedSkills) {
      setLocalSelectedSkills(
        selectedSkills &&
          selectedSkills.length > 0 &&
          selectedSkills.map((skill) => ({
            name: skill.name,
            percentage: skill.percentage,
          }))
      );
    }
  }, [selectedSkills]);

  const toggleCompetency = async (uuid, name, description) => {
    const skillExists = localSelectedSkills.some(
      (skill) => skill?.name === name
    );

    // Update local state
    const updatedSkills = skillExists
      ? localSelectedSkills.filter((skill) => skill?.name !== name)
      : [...localSelectedSkills, { name, percentage: 0 }];

    setLocalSelectedSkills(updatedSkills);
    onSkillsChange(updatedSkills);

    // Determine if we are adding or removing the skill
    const removeSkill = skillExists ? true : false;

    // Update in Redux store
    await dispatch(
      rateSkill({
        skillUuid: uuid,
        skillName: name,
        skillDescription: description,
        removeSkill,
      })
    );
    dispatch(fetchSelectedSkills());
  };

  const isSkillChecked = useCallback(
    (skillName) => {
      return localSelectedSkills.some((skill) => skill.name === skillName);
    },
    [localSelectedSkills]
  );
  if (!isVisible && !isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-hidden">
      <div
        className="absolute inset-0 transition-opacity duration-300 ease-in-out"
        onClick={onClose}
        style={{ opacity: isOpen ? 1 : 0 }}
      />
      <div
        className={`absolute top-0 right-0 w-full md:w-1/4 h-full bg-white transform transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="h-full flex flex-col bg-gray-100 shadow-xl">
          <div className="p-4 flex items-center justify-between">
            <h3 className="text-lg font-semibold">Kompetenzen</h3>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <X size={24} />
            </button>
          </div>
          <div className="px-3 mb-3">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-500" />
              <InputField
                type="text"
                placeholder="Suche nach Kompetenzen..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pl-8"
              />
            </div>
          </div>
          <div className="flex-1 overflow-y-auto p-4">
            <ul>
              {filteredSkills?.length > 0 ? (
                filteredSkills?.map((comp) => (
                  <li key={comp?.id} className="mb-4">
                    <div className="flex items-start">
                      <input
                        type="checkbox"
                        checked={isSkillChecked(comp?.name, comp)}
                        onChange={() =>
                          toggleCompetency(
                            comp?.uuid,
                            comp?.name,
                            comp?.description
                          )
                        }
                        className="mt-1 mr-3 h-4 w-4"
                      />
                      <div>
                        <span className="font-medium">{comp.name}</span>
                        <p className="text-sm text-gray-500 mt-1 overflow-hidden overflow-ellipsis">
                          {truncateText(comp.description, maxDescriptionLength)}
                        </p>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <p className="text-gray-500">Keine Kompetenzen gefunden.</p>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCompetencies;
