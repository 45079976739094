// src/routes.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import PublicRoute from "./PublicRoute";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../pages/Dashboard";
import ProfilePage from "../pages/Profile";
import NotFound from "../404notFound";
import CompetencesGraphDetail from "../pages/Dashboard/competencesGraphDetail";
import Jobs from "../pages/Jobs";
import JobsDetailPage from "./../pages/Jobs/JobsDetailPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PublicRoute />}>
        <Route path="/" element={<Login />} />
      </Route>
      <Route path="/register" element={<PublicRoute />}>
        <Route path="/register" element={<Register />} />
      </Route>
      <Route path="/forgot-password" element={<PublicRoute />}>
        <Route path="/forgot-password" element={<ForgotPassword />} />
      </Route>

      <Route path="/dashboard" element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>
      <Route path="/dashboard/:id" element={<PrivateRoute />}>
        <Route path="/dashboard/:id" element={<CompetencesGraphDetail />} />
      </Route>
      <Route path="/jobs" element={<PrivateRoute />}>
        <Route path="/jobs" element={<Jobs />} />
      </Route>
      <Route path="/jobs/:id" element={<PrivateRoute />}>
        <Route path="/jobs/:id" element={<JobsDetailPage />} />
      </Route>

      <Route path="/Profile" element={<PrivateRoute />}>
        <Route path="/Profile" element={<ProfilePage />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
