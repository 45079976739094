import React from "react";
import MainLogo from "../../../assets/skills_logo.svg.svg";
import { InputField } from "../../../components/InputField";
import DefaultAuthLayout from "../../../components/AuthLayout";

const ForgotPassword = () => {
  return (
    <div className="flex items-center">
      <DefaultAuthLayout />
      <div className="lg:w-[40%] md:w-[50%] w-full items-center h-screen bg-white">
        <div className="w-full max-w-[500px] mx-auto sm:p-8 p-6 bg-white mt-[110px]">
          <div className="text-center mb-10">
            <img src={MainLogo} alt="8Skills Logo" className="mx-auto mb-4" />
            <h2 className="md:text-2xl text-xl font-bold text-[#333]">Passwort vergessen</h2>
          </div>
          <form>
            <div className="mb-5">
              <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium">Benutzername</label>
              <InputField type="email" placeholder="Geben Sie Ihren Benutzernamen ein" />
            </div>
            <div className="text-center mt-5">
              <button className="max-w-[200px] w-full bg-[#BF5547] text-white text-base leading-5 py-3 px-7 rounded-md">Passwort anfordern</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
