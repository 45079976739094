import React, { useEffect } from "react";
import CustomApexBarChart from "../../../../components/Charts/CustomApexBarChart";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchVacancies } from "../../../../redux/slice/vacanciesSlice";
import Loader from "../../../../components/Loader";
import { fetchJobsUserProfile } from "../../../../redux/slice/jobsUsersSlice";

const CompetencyProfileList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { vacancies, loading } = useSelector((state) => state.vacancies);
  const { jobsUserProfile } = useSelector((state) => state.jobsUser);

  useEffect(() => {
    dispatch(fetchJobsUserProfile());
    dispatch(fetchVacancies());
  }, [dispatch]);

  const getMatchedUsernames = (vacancy, jobProfiles) => {
    const matchedUsernames = jobProfiles
      ?.filter((profile) =>
        profile?.skills?.some((skill) =>
          vacancy?.competences?.some(
            (comp) =>
              comp?.skill?.name?.toLowerCase() ===
              skill?.skillName?.toLowerCase()
          )
        )
      )
      .map((profile) =>
        profile?.username
          .split(" ")
          .map((name) => name[0])
          .join("")
      );

    return matchedUsernames.slice(0, 3);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="max-w-full overflow-x-auto bg-[#fff] rounded-[15px]">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="text-left p-4 font-normal text-gray-500 w-1/4">
                  NAME
                </th>
                <th
                  className="text-right p-4 font-normal text-gray-500"
                  colSpan={2}
                >
                  KOMPETENZEN
                </th>
                <th
                  className="text-right p-4 font-normal text-gray-500"
                  colSpan={2}
                >
                  MITARBEITENDE
                </th>
              </tr>
            </thead>
            <tbody>
              {vacancies?.length < 1 ? (
                <h1 className="p-5">No Data is Available</h1>
              ) : (
                vacancies?.map((profile, index) => {
                  const transformedCompetences = profile?.competences?.map(
                    (comp) => ({
                      filled: comp?.value,
                      remaining: 100 - comp?.value,
                      name: comp?.skill?.name,
                    })
                  );

                  const graphData = [
                    {
                      name: "Filled",
                      data: profile?.competences?.map((comp) => comp?.value),
                    },
                    {
                      name: "Remaining",
                      data: profile?.competences?.map(
                        (comp) => 100 - comp?.value
                      ),
                    },
                  ];

                  const matchedUsernames = getMatchedUsernames(
                    profile,
                    jobsUserProfile
                  );

                  return (
                    <tr key={index} className="border-t border-gray-200">
                      <td className="p-4 align-top w-full">
                        <div className="text-base font-semibold text-gray-800 mb-2">
                          {profile?.job?.name}
                        </div>
                        <div className="text-sm text-gray-600">
                          {profile?.job?.description}
                          {/* {profile?.description}  */}
                        </div>
                      </td>
                      <td
                        className="p-4 text-right align-top cursor-pointer"
                        colSpan={2}
                        onClick={() => navigate(`/jobs/${profile._id}`)}
                      >
                        <div className="text-sm text-gray-600 mb-2">
                          Kompetenzprofil
                          <CustomApexBarChart
                            colors={["#F0884D", "#E5E7EB"]}
                            series={graphData}
                            competences={transformedCompetences}
                          />
                        </div>
                      </td>
                      <td className="p-4 text-right" colSpan={2}>
                        <div className="flex justify-end">
                          {matchedUsernames.length > 0 ? (
                            matchedUsernames.map((username, index) => (
                              <div
                                key={index}
                                className={`w-[50px] h-[50px] rounded-full border-2 border-[#F2F6FA] flex items-center justify-center 
                          bg-[#317A9A] text-[#F2F6FA] ${
                            index !== 0 ? "ml-[-5px]" : ""
                          }`}
                              >
                                <span className="text-navy font-semibold">
                                  {username}
                                </span>
                              </div>
                            ))
                          ) : (
                            <span className="text-gray-500">
                              No matching users
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CompetencyProfileList;
