import React, { useState, useEffect } from "react";
import MultiSelect from "../../../components/MultiSelect";
import { ReactComponent as SaveIcon } from "../../../assets/icon/SaveIcon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icon/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserData,
  updateUserProfile
} from "../../../redux/slice/profileSlice";

export default function EditJobModel({ isOpen, onClose }) {
  const { userData } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const [salary, setSalary] = useState(
    userData?.jobPreferences?.expectedSalary
      ? parseInt(userData.jobPreferences.expectedSalary.replace(/[^\d]/g, ""))
      : 0
  );
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectedEmployment, setSelectedEmployment] = useState([]);
  const [preferredLocations, setPreferredLocations] = useState("");

  useEffect(() => {
    if (userData?.jobPreferences) {
      setSalary(
        userData?.jobPreferences?.expectedSalary
          ? parseInt(
              userData.jobPreferences.expectedSalary.replace(/[^\d]/g, "")
            ) // Remove currency symbols
          : 0
      );
    }

    if (userData?.jobPreferences) {
      setSelectedJobs(
        userData?.jobPreferences?.categories?.map((category) => ({
          label: category,
          value: category.toLowerCase().replace(/\s/g, "-")
        }))
      );

      setSelectedEmployment(
        userData?.jobPreferences?.employmentTypes?.map((type) => ({
          label: type,
          value: type.toLowerCase().replace(/\s/g, "-")
        }))
      );

      setPreferredLocations(
        userData.jobPreferences.preferredLocations?.map((place) => ({
          label: place,
          value: place.toLowerCase().replace(/\s/g, "-")
        }))
      );
    }
  }, [userData]);

  if (!isOpen) return null;

  const jobOptions = [
    { value: "uiux", label: "UI/UX-Designer" },
    { value: "frontend", label: "Front-End-Entwickler" },
    { value: "lead-uiux", label: "Leitender UI/UX-Designer" }
  ];

  const employmentOptions = [
    { value: "permanent", label: "Dauerhaft" },
    { value: "internship", label: "Praktikum" },
    { value: "part-time", label: "Teilzeit" }
  ];

  const locationOptions = [
    { value: "berlin", label: "Berlin" },
    { value: "munich", label: "München" },
    { value: "hamburg", label: "Hamburg" },
    { value: "cologne", label: "Köln" },
    { value: "frankfurt", label: "Frankfurt" },
    { value: "stuttgart", label: "Stuttgart" },
    { value: "düsseldorf", label: "Düsseldorf" },
    { value: "london", label: "London" },
    { value: "paris", label: "Paris" },
    { value: "tokyo", label: "Tokyo" },
    { value: "singapore", label: "Singapore" },
    { value: "sydney", label: "Sydney" }
  ];

  const handleSave = () => {
    const updatedJobPreferences = {
      ...userData.jobPreferences,
      categories: selectedJobs.map((job) => job.label),
      employmentTypes: selectedEmployment.map((employment) => employment.label),
      expectedSalary: `€${salary} PA`,
      preferredLocations: preferredLocations.map((job) => job.label)
    };

    dispatch(
      updateUserProfile({
        id: userData.id,
        userData: {
          ...userData,
          jobPreferences: updatedJobPreferences
        }
      })
    ).then(() => {
      dispatch(fetchUserData());
      onClose();
    });
  };

  return (
    <div className="fixed inset-0 flex items-start justify-center bg-gray-800 bg-opacity-50 z-10 pt-6 lg:pt-11">
      <div className="bg-white rounded-[15px] shadow-lg max-w-[830px] w-[calc(100%-30px)] max-h-[95vh] overflow-visible">
        <div className="flex justify-between items-center px-5 py-[15px] border-b border-[#CCCCCC]">
          <h3 className="text-2xl font-bold">Jobeinstellungen bearbeiten</h3>
        </div>
        <div className="p-5">
          <div className="mb-5">
            <label className="block mb-[10px] font-medium text-sm leading-[18px]">
              Jobkategorie
            </label>
            <MultiSelect
              options={jobOptions}
              selectedOptions={selectedJobs}
              onChange={setSelectedJobs}
            />
          </div>
          <div className="mb-5">
            <label className="block mb-[10px] font-medium text-sm leading-[18px]">
              Art der Beschäftigung
            </label>
            <MultiSelect
              options={employmentOptions}
              selectedOptions={selectedEmployment}
              onChange={setSelectedEmployment}
            />
          </div>
          <div className="mb-5">
            <div className="flex items-center justify-between mb-[10px]">
              <label className="block font-medium text-sm leading-[18px]">
                Erwartetes Gehalt
              </label>
              <div className="text-sm font-medium text-gray-700 whitespace-nowrap">
                €{salary.toLocaleString()} PA
              </div>
            </div>
            <div className="range">
              <input
                type="range"
                min="0"
                max="100000"
                value={salary}
                onChange={(e) => setSalary(Number(e.target.value))}
                className="w-full h-2 rounded-lg appearance-none cursor-pointer custom-range-slider"
                style={{
                  background: `linear-gradient(to right, #BF5547 0%, #BF5547 ${
                    (salary / 100000) * 100
                  }%, #E5E7EB ${(salary / 100000) * 100}%, #E5E7EB 100%)`
                }}
              />
            </div>
          </div>
          <div className="mb-5">
            <label className="block mb-[10px] font-medium text-sm leading-[18px]">
              Bevorzugter Standort
            </label>
            <MultiSelect
              options={locationOptions}
              selectedOptions={preferredLocations}
              onChange={setPreferredLocations}
            />
          </div>
          <div className="flex justify-end sm:gap-[20px] gap-[10px]  mt-5">
            <button
              className="text-[#333333] text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
              onClick={onClose}
            >
              <CloseIcon />
              Stornieren
            </button>
            <button
              className="bg-[#317A9A] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
              onClick={handleSave}
            >
              <SaveIcon />
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
