import React from "react";
import CompanyImage from "../../../../assets/images/companyLogoImage.png";
import { ReactComponent as BtnArrowRight } from "../../../../assets/icon/BtnArrowRight.svg";
import LocationIcon from "../../../../assets/icon/LocationIcon.svg";
import PeopleIcon from "../../../../assets/icon/People_Black.svg";
import BrifcaseIcon from "../../../../assets/icon/Brifcase_Black.svg";

const CompanyCard = ({ logo, name, location, employees, openPositions }) => (
  <div className="bg-[#F2F6FA] p-5 rounded-[15px]">
    <div className="flex items-center gap-5 mb-5">
      <div className="w-20 h-20 rounded-full">
        <img
          src={CompanyImage}
          alt={`${name} logo`}
          className="w-full h-full object-cover"
        />
      </div>
      <h3 className="w-[calc(100%-100px)] lg:text-xl md:text-lg text-base font-bold text-[#333333] mb-5">
        {name}
      </h3>
    </div>
    <div className="flex items-center flex-wrap mb-5 gap-5">
      <div className="flex items-center gap-[10px] px-5 py-[5px] rounded-full text-sm font-normal text-[#333333] bg-white">
        <img src={LocationIcon} alt="icon" height={16} width={14} />
        {location}
      </div>
      <div className="flex items-center gap-[10px] px-5 py-[5px] rounded-full text-sm font-normal text-[#333333] bg-white">
        <img src={PeopleIcon} alt="icon" height={16} width={14} />
        Anzahl der Mitarbeiter: {employees}
      </div>
      <div className="flex items-center gap-[10px] px-5 py-[5px] rounded-full text-sm font-normal text-[#333333] bg-white">
        <img src={BrifcaseIcon} alt="icon" height={16} width={14} />
        Offene Stellen: {openPositions}
      </div>
    </div>
    <button className="bg-[#317A9A] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit">
      Profil anzeigen <BtnArrowRight />
    </button>
  </div>
);

const ContactedCompanies = () => {
  const companies = [
    {
      logo: "../../../assets/images/companyLogoImage.png",
      name: "Spotech Technical Solutions",
      location: "Berlin",
      employees: 345,
      openPositions: 50,
    },
    {
      logo: "/api/placeholder/32/32",
      name: "Infratech PVT LTD",
      location: "Munich",
      employees: 345,
      openPositions: 50,
    },
    {
      logo: "/api/placeholder/32/32",
      name: "G Technical Solutions",
      location: "Munich",
      employees: 345,
      openPositions: 50,
    },
    {
      logo: "/api/placeholder/32/32",
      name: "Diego Technical Solutions",
      location: "Berlin",
      employees: 345,
      openPositions: 50,
    },
    {
      logo: "/api/placeholder/32/32",
      name: "Vehemet Tech PV LTD",
      location: "Berlin",
      employees: 345,
      openPositions: 50,
    },
    {
      logo: "/api/placeholder/32/32",
      name: "Wonka Industries",
      location: "Munich",
      employees: 345,
      openPositions: 50,
    },
  ];

  return (
    <>
      <div className="flex justify-between items-center px-5 py-[15px] border-b border-[#CCCCCC]">
        <h3 className="text-lg font-bold">Kontaktierte Unternehmen</h3>
      </div>
      <div className="p-5 grid lg:grid-cols-2 gap-5">
        {companies.map((company, index) => (
          <CompanyCard key={index} {...company} />
        ))}
      </div>
    </>
  );
};

export default ContactedCompanies;
