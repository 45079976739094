import React from "react";
import DefaultLayoutImageSrc from "../../assets/images/authlayoutImage.svg";

const DefaultAuthLayout = () => {
  return (
    <div className="hidden md:flex lg:w-[60%] w-[50%] h-full flex items-center justify-center bg-[#DAF3F5] min-h-screen">
      <img src={DefaultLayoutImageSrc} alt="Team" height={741.79} width={762} />
    </div>
  );
};

export default DefaultAuthLayout;
