import React from "react";
import DownArrow from "../../assets/icon/DownArrow.svg";

const Select = ({ options, placeholder, onChange, value }) => {
  return (
    <div className="relative">
      <select
        onChange={onChange}
        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 appearance-none bg-white text-gray-700"
        value={value}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <img
        src={DownArrow}
        alt="Arrow"
        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
        size={20}
      />
    </div>
  );
};

export default Select;
