import React, { useEffect, useState } from "react";
import MultiSelect from "../../../components/MultiSelect";
import { ReactComponent as SaveIcon } from "../../../assets/icon/SaveIcon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icon/CloseIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserData,
  updateUserProfile
} from "../../../redux/slice/profileSlice";

const KeyCompetenciesEditor = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.profile);

  const options = [
    { value: "UX", label: "UX" },
    { value: "UI", label: "UI" },
    { value: "Adobe XD", label: "Adobe XD" },
    { value: "Mobile Apps", label: "Mobile Apps" },
    { value: "Benutzerforschung", label: "Benutzerforschung" },
    { value: "Wireframing", label: "Wireframing" },
    { value: "Informationsarchitektur", label: "Informationsarchitektur" },
    {
      value: "Gestaltung von Benutzeroberflächen",
      label: "Gestaltung von Benutzeroberflächen"
    }
  ];

  const [selectedSkills, setSelectedskills] = useState([]);

  useEffect(() => {
    if (userData?.keySkills) {
      setSelectedskills(
        userData?.keySkills?.skills?.map((skills) => ({
          label: skills,
          value: skills.toLowerCase().replace(/\s/g, "-")
        }))
      );
    }
  }, [userData]);

  if (!isOpen) return null;

  const handleSave = () => {
    const updatedJobPreferences = {
      ...userData?.keySkills,
      skills: selectedSkills?.map((skill) => skill.label)
    };

    dispatch(
      updateUserProfile({
        id: userData.id,
        userData: {
          ...userData,

          keySkills: updatedJobPreferences
        }
      })
    ).then(() => {
      dispatch(fetchUserData());
      onClose();
    });
  };
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-10">
      <div className="bg-white rounded-[15px] shadow-lg max-w-[830px] w-[calc(100%-30px)] max-h-[95vh] ">
        <div className="flex justify-between items-center px-5 py-[15px] border-b border-[#CCCCCC]">
          <h3 className="text-2xl font-bold">
            Bearbeiten Sie Schlüsselkompetenzen
          </h3>
        </div>
        <div className="p-5 min-h-[160px]">
          <MultiSelect
            options={options}
            selectedOptions={selectedSkills}
            onChange={setSelectedskills}
          />
          <div className="flex justify-end sm:gap-[20px] gap-[10px]  mt-5">
            <button
              className="text-[#333333] text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
              onClick={onClose}
            >
              <CloseIcon />
              Stornieren
            </button>
            <button
              className="bg-[#317A9A] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
              onClick={handleSave}
            >
              <SaveIcon />
              Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyCompetenciesEditor;
