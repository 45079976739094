import React, { useState } from "react";
import Header from "../../layout/Header";
import AddIcon from "../../assets/icon/Add.svg";
import StateCard from "../Dashboard/StateCard";
import UsersIcon from "../../assets/icon/UsersIcon.svg";
import GraphUpIcon from "../../assets/icon/GraphUpIcon.svg";
import InnovativePower from "../../assets/icon/InnovativePower.svg";
import RedundancyIcon from "../../assets/icon/BagIcon.svg";
import CompetencyScore from "../../assets/icon/CompetencyScoreIcon.svg";
import { ReactComponent as JobsIcon } from "../../assets/icon/JobsIcon_black.svg";
import { ReactComponent as PaperIcon } from "../../assets/icon/PaperIcon.svg";
import CompetencyProfileList from "./Tabs/CompetencyProfileList";
import Footer from "../../layout/Footer";

const Jobs = () => {
  const tabs = [
    { id: "arbeitsplätze", label: "Arbeitsplätze", icon: JobsIcon },
    {
      id: "arbeitsplatzbeschreibungen",
      label: "Arbeitsplatzbeschreibungen",
      icon: PaperIcon,
    },
  ];
  const [activeTab, setActiveTab] = useState("arbeitsplatzbeschreibungen");

  return (
    <div>
      <Header />
      <div className="bg-[#F2F6FA] min-h-screen">
        <div className="container">
          <div className="py-[60px] px-[10px]">
            <div className="flex sm:flex-row flex-col sm:justify-between sm:items-center mb-[30px] md:gap-0 gap-5">
              <h2 className="md:text-2xl sm:text-xl text-lg text-[#333333] font-bold leading-[29px]">
                Arbeitsplätze
              </h2>
              <button className="bg-[#BF5547] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit">
                <img src={AddIcon} alt="Globe" />
                Neuer Arbeitsplatz
              </button>
            </div>
            <div className="bg-white p-5 rounded-[20px]">
              <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 gap-4 mb-5">
                <StateCard
                  icon={UsersIcon}
                  value="12.3"
                  label="Humanpotential"
                  trend={-2}
                />
                <StateCard
                  icon={GraphUpIcon}
                  value="0"
                  label="Ökonomische Temperatur"
                />
                <StateCard
                  icon={RedundancyIcon}
                  value="2.42"
                  label="Redundanz"
                  trend={4}
                />
                <StateCard
                  icon={InnovativePower}
                  value="1.24"
                  label="Innovationskraft"
                  trend={-3}
                />
                <StateCard
                  icon={CompetencyScore}
                  value="0"
                  label="Kompetenzwert"
                />
              </div>
              <div className="bg-[#F2F6FA] p-5 rounded-[15px]">
                <div className="flex gap-5 items-center mb-5 md:flex-nowrap flex-wrap">
                  {tabs?.map((tab) => (
                    <button
                      key={tab.id}
                      className={`py-2.5 px-[15px] rounded-[10px] text-sm leading-[17px] p-[9px] flex items-center justify-center gap-[14px] transition-colors duration-300 ${
                        activeTab === tab.id
                          ? "bg-white text-[#317A9A] border-b-2 border-[#317A9A]"
                          : "bg-[#FFFFFF] text-gray-600 hover:bg-gray-200"
                      }`}
                      onClick={() => setActiveTab(tab.id)}
                    >
                      <tab.icon
                        className={`stroke-current w-5 h-5  ${
                          activeTab === tab.label
                            ? "text-[#317A9A]"
                            : "text-[#88888]"
                        }`}
                      />
                      {tab.label}
                    </button>
                  ))}
                </div>
                {activeTab === "arbeitsplatzbeschreibungen" && (
                  <CompetencyProfileList />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Jobs;
