import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { useGetApi } from "../../hook/useApiHook";

export const fetchJobsUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  async (_, { rejectWithValue }) => {
    try {
      const response = await useGetApi(
        `${process.env.REACT_APP_API_URL}/users/get_user_profile`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data || error.message);
    }
  }
);

const jobsUsersSlice = createSlice({
  name: "jobsUserProfile",
  initialState: {
    jobsUserProfile: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobsUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobsUserProfile.fulfilled, (state, action) => {
        state.jobsUserProfile = action.payload;
        state.loading = false;
      })
      .addCase(fetchJobsUserProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default jobsUsersSlice.reducer;
