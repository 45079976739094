import React from "react";
import Chart from "react-apexcharts";

const NegativeValuesChart = () => {
  const maxValue = 100;
  const actualValues = [60, -30, -50, -45, -60, -70, -20];

  const series = [
    {
      name: "Solid",
      data: actualValues.map((value) =>
        value >= 0 ? Math.min(value, maxValue) : Math.max(value, -maxValue)
      ),
    },
    {
      name: "Transparent Negative",
      data: actualValues.map((value) => {
        if (value >= 0) return -maxValue;
        if (value > -maxValue) return -maxValue - value;
        return 0;
      }),
    },
    {
      name: "Transparent Positive",
      data: actualValues.map((value) => (value >= 0 ? 0 : maxValue)),
    },
    {
      // This series creates the transparent grey fill above positive values
      name: "Transparent Fill Above Positive",
      data: actualValues.map((value) =>
        value >= 0 ? maxValue - Math.min(value, maxValue) : 0
      ),
    },
  ];

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stackType: "100%",
    parentHeightOffset: 0,
    plotOptions: {
      bar: {
        columnWidth: "80%",
        borderRadius: 6,
        borderRadiusWhenStacked: "all",
        barHeight: "100%",
        horizontal: false,
      },
    },
    colors: [
      ({ value }) => (value >= 0 ? "#009951" : "#083D4E"), // Green for positive values
      "rgba(229, 231, 235)", // Transparent grey for negative
      "rgba(229, 231, 235)", // Transparent grey for positive base
      "rgba(229, 231, 235)", // Transparent grey fill above positive values
    ],
    fill: {
      opacity: [1, 0.3, 0.3, 0.3], // Opacity for transparent areas
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  return <Chart options={options} series={series} type="bar" height={200} />;
};

export default NegativeValuesChart;