import React, { useState, useEffect } from "react";

function CountUp({ targetNumber }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const step = targetNumber < 500 ? 1 : 0.01; // Small step for decimals

    const interval = setInterval(
      () => {
        if (count < targetNumber) {
          setCount((prevCount) => Math.min(prevCount + step, targetNumber));
        }
      },
      targetNumber > 10 ? 0 : 20
    ); // Adjust this value for smoothness

    return () => {
      clearInterval(interval);
    };
  }, [count, targetNumber]);

  return <span>{count}</span>;
}

export default CountUp;
