/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Header from "../../layout/Header";
import UsersIcon from "../../assets/icon/UsersIcon.svg";
import GraphUpIcon from "../../assets/icon/GraphUpIcon.svg";
import InnovativePower from "../../assets/icon/InnovativePower.svg";
import RedundancyIcon from "../../assets/icon/BagIcon.svg";
import CompetencyScore from "../../assets/icon/CompetencyScoreIcon.svg";
import CompetencyScoreIcon from "../../assets/icon/CompetenciesIcon.svg";
import JobsIcon from "../../assets/icon/JobsIcon.svg";
import EmployeeIcon from "../../assets/icon/EmployeesIcon.svg";
import { ReactComponent as SearchIcon } from "../../assets/icon/SearchIcon.svg";
import { ReactComponent as Handshake } from "../../assets/icon/Handshake.svg";
import { ReactComponent as EarthIcon } from "../../assets/icon/EarthIcon.svg";
import AddIcon from "../../assets/icon/Add.svg";
import Footer from "../../layout/Footer";
import { SidebarCard } from "./SideBarCard";
import DownArrow from "../../assets/icon/DownArrow.svg";
import StateCard from "./StateCard";
import EmployeeCompetenciesDashboard from "./EmployeeCompetencies";
import WorldMap from "./WorldMap";
import TableRow from "./TableRow";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchVacancies } from "../../redux/slice/vacanciesSlice";
import toast from "react-hot-toast";
import CustomApexBarChart from "../../components/Charts/CustomApexBarChart";
import { fetchSelectedSkills } from "../../redux/slice/skillSlice";
import { useNavigate } from "react-router-dom";
import { useHumaticsCalculations } from "../../hook/useHumaticsCalculations";
import { fetchIndustries } from "../../redux/slice/industriesSlice";
import CircularProgressChart from "../../components/Charts/CircularProgressChart";
import NegativeValuesChart from "../../components/Charts/NegativeValuesChart";

const Dashboard = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(() => {
    return sessionStorage.getItem("dashboardActiveTab") || "matching";
  });
  const { vacancies, loading, error } = useSelector((state) => state.vacancies);
  console.log("vacancies: ", vacancies);

  const { industries, status } = useSelector((state) => state.industries);
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [jobs, setJobs] = useState([]);

  const { calculateMetrics } = useHumaticsCalculations();

  useEffect(() => {
    sessionStorage.setItem("dashboardActiveTab", activeTab);
  }, [activeTab]);
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchIndustries());
    }
  }, [dispatch, status]);
  const averageMetrics = vacancies?.reduce(
    (acc, position) => {
      const metrics = calculateMetrics(
        position.competences.map((c) => c.value)
      );
      return {
        h: metrics.h,
        t: metrics.t,
        r: metrics.r,
        i: metrics.i,
        c: metrics.c,
        competencies: metrics.competencies,
      };
    },
    { h: 0, t: 0, r: 0, i: 0, c: 0 }
  );

  const navigate = useNavigate();
  const selectedSkills = useSelector(
    (state) => state?.skills?.selectedSkill?.skills
  );

  useEffect(() => {
    dispatch(fetchVacancies());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchSelectedSkills());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`);
    }
  }, [error]);

  const tabs = [
    { id: "matching", label: "Matching", icon: Handshake },
    { id: "search", label: "Suchen", icon: SearchIcon },
    { id: "competencymap", label: "Kompetenzkarte", icon: EarthIcon },
  ];

  const handleBarClick = (id) => {
    navigate(`/dashboard/${id}`);
  };

  const handleIndustryChange = (e) => {
    const selectedId = parseInt(e.target.value);
    const industry = industries.find((ind) => ind.id === selectedId);
    setSelectedIndustry(selectedId);
    setJobs(industry ? industry.jobs : []);
  };

  const [filteredVacancies, setFilteredVacancies] = useState([]);
  console.log("filteredVacancies: ", filteredVacancies);

  const handleJobChange = (event) => {
    const jobName = event.target.value;

    const filtered = vacancies.filter(
      (vacancy) => vacancy.job.name === jobName
    );
    setFilteredVacancies(filtered);
  };
  return (
    <>
      <Header />
      <div className="bg-[#F2F6FA] min-h-screen">
        <div className="container">
          <div className="py-[60px] px-[10px]">
            <div className="flex sm:flex-row flex-col sm:justify-between sm:items-center mb-[30px] md:gap-0 gap-5">
              <h2 className="md:text-2xl sm:text-xl text-lg text-[#333333] font-bold leading-[29px]">
                Willkommen Admin!
              </h2>
              <button
                className="bg-[#BF5547] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit"
                onClick={() => {
                  navigate("/profile");
                }}
              >
                <img src={AddIcon} alt="Globe" />
                Neue Kompetenz hinzufügen
              </button>
            </div>

            <div className="bg-white p-5 rounded-[20px]">
              <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 gap-4 mb-5">
                <StateCard
                  icon={UsersIcon}
                  // value="12.3"
                  value={averageMetrics.h.toFixed(2)}
                  label="Humanpotential"
                  trend={-2}
                />
                <StateCard
                  icon={GraphUpIcon}
                  // value="0"
                  label="Ökonomische Temperatur"
                  value={averageMetrics.t.toFixed(2)}
                />
                <StateCard
                  icon={RedundancyIcon}
                  // value="2.42"
                  value={averageMetrics.r.toFixed(2)}
                  label="Redundanz"
                  trend={4}
                />
                <StateCard
                  icon={InnovativePower}
                  // value="1.24"
                  value={averageMetrics.i.toFixed(2)}
                  label="Innovationskraft"
                  trend={-3}
                />
                <StateCard
                  icon={CompetencyScore}
                  // value="0"
                  value={averageMetrics.c.toFixed(2)}
                  label="Kompetenzwert"
                />
              </div>
              <div className="flex lg:flex-row flex-col gap-5 bg-[#F2F6FA] p-5 rounded-[15px]">
                <div className="lg:w-[260px] w-full space-y-4">
                  <SidebarCard
                    icon={CompetencyScoreIcon}
                    label="Kompetenzen"
                    // value="125"
                    value={averageMetrics?.competencies}
                  />
                  <SidebarCard
                    icon={JobsIcon}
                    label="Arbeitsplätze"
                    // value="32"
                    value={vacancies.length}
                  />
                  <SidebarCard
                    icon={EmployeeIcon}
                    label="Mitarbeiter"
                    value="0"
                  />
                </div>

                <div className="lg:w-[calc(100%-280px)] w-full">
                  <div className="flex gap-5 items-center mb-5 md:flex-nowrap flex-wrap">
                    {tabs?.map((tab) => (
                      <button
                        key={tab.id}
                        className={`max-w-[160px] w-full rounded-[10px] text-sm leading-[17px] p-[9px] flex items-center justify-center gap-[14px] transition-colors duration-300 ${
                          activeTab === tab.id
                            ? "bg-white text-[#317A9A] border-b-2 border-[#317A9A]"
                            : "bg-[#FFFFFF] text-gray-600 hover:bg-gray-200"
                        }`}
                        onClick={() => setActiveTab(tab.id)}
                      >
                        <tab.icon
                          className={`stroke-current w-5 h-5  ${
                            activeTab === tab.label
                              ? "text-[#317A9A]"
                              : "text-[#88888]"
                          }`}
                        />
                        {tab.label}
                      </button>
                    ))}
                  </div>
                  {activeTab === "matching" && (
                    <>
                      <div className="flex md:flex-nowrap flex-wrap gap-5 mb-5">
                        <div className="max-w-[295px] w-full">
                          <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium leading-[19px]">
                            Branche wählen
                          </label>
                          <div className="relative">
                            <select
                              className="border border-[#CCCCCC] rounded-lg px-4 py-2 w-full appearance-none"
                              value={selectedIndustry}
                              onChange={handleIndustryChange}
                            >
                              <option value="">Branche wählen</option>
                              {industries?.map((industry) => (
                                <option key={industry.id} value={industry.id}>
                                  {industry.name}
                                </option>
                              ))}
                            </select>
                            <img
                              src={DownArrow}
                              alt="Arrow"
                              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
                              size={20}
                            />
                          </div>
                        </div>
                        <div className="max-w-[295px] w-full">
                          <label className="text-[#333] md:text-base text-sm mb-[10px] block font-medium leading-[19px]">
                            Arbeitsplatz wählen
                          </label>
                          <div className="relative">
                            <select
                              className="border border-[#CCCCCC] rounded-lg px-4 py-2 w-full appearance-none"
                              disabled={!selectedIndustry}
                              onChange={handleJobChange}
                            >
                              <option>Arbeitsplatz wählen</option>
                              {jobs?.map(({ job }) => (
                                <option key={job.id} value={job.name}>
                                  {job?.name}
                                </option>
                              ))}
                            </select>
                            <img
                              src={DownArrow}
                              alt="Arrow"
                              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
                              size={20}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="overflow-x-auto bg-white rounded-[15px]">
                        <table className="table-fixed w-full border-collapse">
                          <thead>
                            <tr className="text-left border-b border-gray-200">
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[170px]">
                                Firma
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[195px]">
                                ARBEITSPLATZ
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap 2xl:w-auto w-[420px]">
                                DEINE KOMPETENZEN
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[180px]">
                                Kompetenzlücken
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[90px]">
                                Ähnliche
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[130px]">
                                Seminare
                              </th>
                              <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[130px]">
                                Konakt
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredVacancies.map((item) => {
                              const graphData = {
                                series: [
                                  {
                                    name: "Filled",
                                    data:
                                      item?.competences?.map(
                                        (comp) => comp.value
                                      ) || [],
                                  },
                                  {
                                    name: "Remaining",
                                    data:
                                      item?.competences?.map(
                                        (comp) => 100 - comp.value
                                      ) || [],
                                  },
                                ],
                                competences:
                                  item?.competences?.map((comp) => ({
                                    name: comp.skill.name,
                                  })) || [],
                              };

                              return (
                                <tr className="border-b border-gray-200">
                                  <td className="text-[#333333] font-normal text-sm px-5 py-[15px]">
                                    {item?.company?.name}
                                  </td>
                                  <td
                                    className="px-5 py-[15px] text-center align-top"
                                    onClick={() => handleBarClick(item?._id)}
                                  >
                                    <p className="text-sm leading-[17px] text-center mb-[10px]">
                                      Altenpflege-Leitung
                                    </p>
                                    <CustomApexBarChart
                                      // eslint-disable-next-line no-sequences
                                      colors={["#317A9A", "#F2F6FA"]}
                                      series={graphData.series}
                                      competences={graphData.competences}
                                    />
                                  </td>
                                  <td className="px-5 py-[15px] align-top">
                                    <div className="flex gap-3">
                                      <div className="px-2">
                                        <p className="text-sm leading-[17px] text-center mb-[10px]">
                                          Kompetenzprofil
                                        </p>
                                        {/* <BarGraph colors={"#F0884D"} /> */}
                                        <CustomApexBarChart
                                          colors={["#F0884D", "#F2F6FA"]}
                                          series={[
                                            {
                                              name: "Filled",
                                              data:
                                                selectedSkills?.map(
                                                  (skill) => skill?.percentage
                                                ) || [],
                                            },
                                            {
                                              name: "Remaining",
                                              data:
                                                selectedSkills?.map(
                                                  (skill) =>
                                                    100 - skill?.percentage
                                                ) || [],
                                            },
                                          ]}
                                          competences={
                                            selectedSkills?.map((skill) => ({
                                              name: skill?.name,
                                            })) || []
                                          }
                                        />
                                      </div>
                                      <div className="px-2">
                                        <p className="text-sm leading-[17px] text-center mb-[50px]">
                                          Humanpotential
                                        </p>
                                        <CircularProgressChart
                                          label={"Humanpotential"}
                                          percentage={80}
                                          height={60}
                                          width={60}
                                        />
                                      </div>
                                      <div className="px-2">
                                        <p className="text-sm leading-[17px] text-center mb-[50px]">
                                          Heatmap
                                        </p>
                                        {/* <div>
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="heatmap"
                height={350}
              />
            </div> */}
                                        <div
                                          className={`w-3 h-3 rounded-[3px] mx-auto ${
                                            true
                                              ? "bg-[#009951]"
                                              : "bg-[#BF5547]"
                                          }`}
                                        ></div>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="px-5 py-[15px] align-top">
                                    <NegativeValuesChart />
                                  </td>
                                  <td className="px-5 py-[10px]">
                                    <div className="bg-[#317A9A] w-[50px] h-[50px] rounded-full text-white text-base font-medium flex items-center justify-center">
                                      KKH
                                    </div>
                                  </td>
                                  <td className="px-5 py-[10px]">
                                    <div className="bg-[#F2F6FA] text-sm text-[#333333] p-[10px] rounded-[5px] whitespace-nowrap">
                                      1 gefunden
                                    </div>
                                  </td>
                                  <td className="px-5 py-[10px]">
                                    <div className="bg-[#F2F6FA] text-sm text-[#333333] p-[10px] rounded-[5px] whitespace-nowrap">
                                      Nachricht
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                            {/* <TableRow
                            firm="Unbekannt"
                            job="Altenpflege-Leitung"
                            competencies={80}
                            gaps={true}
                            similar="KKH"
                            seminars="1 gefunden"
                            contact="Nachricht"
                          /> */}
                          </tbody>
                        </table>
                        {loading ? (
                          <Loader />
                        ) : (
                          <table className="table-fixed w-full border-collapse">
                            <thead>
                              {/* <tr className="text-left border-b border-gray-200">
                                <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[150px]">
                                  Name
                                </th>
                                <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[150px]">
                                  Kompetenzen
                                </th>
                                <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[190px]">
                                  Arbeitsplatz
                                </th>
                                <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap uppercase w-[150px]">
                                  Personalkosten
                                </th>
                                <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[130px]">
                                  Unit
                                </th>
                                <th className="text-[#333333] font-medium text-sm p-5 whitespace-nowrap w-[130px]">
                                  Konakt
                                </th>
                              </tr> */}
                            </thead>

                            {/* <tbody className="divide-y divide-gray-200">
                              {filteredVacancies?.map((employee, index) => {
                                const competences = employee?.competences
                                  ?.filter((competence) => {
                                    const matchingSkill = selectedSkills?.find(
                                      (skill) =>
                                        skill.name === competence?.skill?.name
                                    );
                                    return (
                                      matchingSkill &&
                                      matchingSkill?.percentage >=
                                        competence?.value
                                    );
                                  })
                                  ?.map((competence) => ({
                                    filled: competence?.value,
                                    remaining: 100 - competence?.value,
                                    name: competence?.skill?.name,
                                  }));

                                // Only render the row if there are matching competences
                                if (!competences || competences?.length === 0) {
                                  return null;
                                }

                                const graphData = [
                                  {
                                    name: "Filled",
                                    data: competences?.map((c) => c?.filled),
                                  },
                                  {
                                    name: "Remaining",
                                    data: competences?.map((c) => c?.remaining),
                                  },
                                ];

                                return (
                                  <tr
                                    key={index}
                                    className="border-b border-gray-200"
                                  >
                                    <td className="text-[#333333] font-normal text-sm px-5 py-[15px]">
                                      {employee?.company?.name}
                                    </td>
                                    <td
                                      className="px-5 py-[15px] text-center align-top cursor-pointer"
                                      onClick={() =>
                                        handleBarClick(employee?._id)
                                      }
                                    >
                                      <CustomApexBarChart
                                        colors={["#317A9A", "#E5E7EB"]}
                                        series={graphData}
                                        competences={competences}
                                      />
                                    </td>
                                    <td className="text-[#333333] font-normal text-sm px-5 py-[15px]">
                                      {employee?.job?.name}
                                    </td>
                                    <td className="px-6 py-4">
                                      <span className="bg-[#009951] text-white rounded-[5px] font-normal text-sm px-[5px] py-[4px] leading-[18px]">
                                        {employee?.salary}
                                      </span>
                                    </td>
                                    <td className="text-[#333333] font-normal text-sm px-5 py-[15px]">
                                      {employee?.unit?.name}
                                    </td>
                                    <td className="px-6 py-4">
                                      <div className="relative">
                                        <select className="border border-[#CCCCCC] rounded-lg px-4 py-2 w-full appearance-none">
                                          <option>Aktionen</option>
                                        </select>
                                        <img
                                          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 pointer-events-none"
                                          src={DownArrow}
                                          alt="Arrow"
                                          size={20}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody> */}
                          </table>
                        )}
                      </div>
                    </>
                  )}
                  {activeTab === "search" && <EmployeeCompetenciesDashboard />}
                  {activeTab === "competencymap" && (
                    <div className="w-full max-w-6xl mx-auto">
                      <WorldMap />
                    </div>
                  )}
                </div>
              </div>

              <div className="grid lg:grid-cols-3 mt-10 mb-5 lg:gap-3 gap-[30px]">
                <div>
                  <h2 className="lg:text-2xl md:text-[20px] text-lg text-[#333333] font-bold mb-[15px]">
                    Dein perfekter Job
                  </h2>
                  <ul className="list-none">
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Matching & Vergleichen
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Kompetenzen finden
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Kompetenzlücken erkennen
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Seminare finden
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Kompetenzmappe
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="lg:text-2xl md:text-[20px] text-lg text-[#333333] font-bold mb-[15px]">
                    Die perfekte Wissensorganisation
                  </h2>
                  <ul className="list-none">
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Matching & Vergleichen
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Kompetenzen finden
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Simulieren & Visualisieren
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        KPI's zum Performancemanagement
                      </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <h2 className="lg:text-2xl md:text-[20px] text-lg text-[#333333] font-bold mb-[15px]">
                    Das perfekte Team erstellen
                  </h2>
                  <ul className="list-none">
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Teamkompetenzen erstellen
                      </a>
                    </li>
                    <li>
                      <a className="text-base leading-[19px] font-medium mb-[10px] inline-block">
                        Simulieren & Visualisieren
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
