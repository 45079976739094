import React from 'react';
import Select from 'react-select';

const MultiSelect = ({ placeholder, options, selectedOptions, onChange }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: '#cccccc',
      boxShadow: 'none',
      borderRadius: '7px',
      '&:hover': { borderColor: '#cccccc' },
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: '#e5e7eb', 
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: '#374151', 
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: '#374151', 
      '&:hover': {
        backgroundColor: '#374151',
        color: 'white',
      },
    }),
  };

  return (
    <>
      <Select
        isMulti
        value={selectedOptions}
        onChange={onChange}
        options={options}
        styles={customStyles}
        placeholder={placeholder}
      />
    </>
  );
};

export default MultiSelect;