import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../../layout/Header";
import Footer from "../../../layout/Footer";
// import DeleteIcon from "../../../assets/icon/deleteIcon.svg";
// import EditIcon from ".././../../assets/icon/editIcon_black.svg";

import { ReactComponent as InfoIcon } from "../../../assets/icon/InfoIcon.svg";
import MicroChart from "../../../components/Charts/MicroChart";
import { useDispatch, useSelector } from "react-redux";
import { fetchVacancies } from "../../../redux/slice/vacanciesSlice";
import ColorBar from "../../../components/Charts/ColorBarGraph";
import { useHumaticsCalculations } from "../../../hook/useHumaticsCalculations";
import CountUp from "../../../components/CountUp";

const JobDetailPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { vacancies } = useSelector((state) => state.vacancies);
  const { calculateMetrics } = useHumaticsCalculations();

  const averageMetrics = vacancies.reduce(
    (acc, position) => {
      const metrics = calculateMetrics(
        position.competences.map((c) => c.value)
      );
      return {
        h: metrics.h,
        t: metrics.t,
        r: metrics.r,
        i: metrics.i,
        c: metrics.c,
        competencies: metrics.competencies,
        mu: metrics.mu,
      };
    },
    { h: 0, t: 0, r: 0, i: 0, c: 0, mu: 0 }
  );

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    dispatch(fetchVacancies());
  }, [dispatch]);

  useEffect(() => {
    if (vacancies && id) {
      const foundVacancy = vacancies.find((vac) => vac._id === id);

      if (foundVacancy && foundVacancy.competences) {
        const newChartData = foundVacancy.competences.map((comp) => ({
          skill: comp.skill.name,
          percentage: comp.value,
        }));
        setChartData(newChartData);
      }
    }
  }, [vacancies, id]);

  const ScoreCard = ({ title, score, icon }) => (
    <div className="bg-[#F2F6FA] p-4 rounded-lg">
      <div className="flex items-center gap-2.5 mb-2">
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        {icon}
      </div>
      <p className="text-2xl font-bold">
        <CountUp targetNumber={score} />
      </p>
    </div>
  );

  const handleSkillUpdate = () => {};
  return (
    <>
      <Header />
      <div className="bg-[#F2F6FA] min-h-screen">
        <div className="container">
          <div className="py-[60px] px-[10px]">
            <div className="flex sm:flex-row flex-col sm:justify-between sm:items-center mb-[30px] md:gap-0 gap-5">
              <h2 className="md:text-2xl sm:text-xl text-lg text-[#333333] font-bold leading-[29px]">
                Backoffice
              </h2>
              {/* <button className="absolute top-[120px] left-[1511px] bg-[#ffffff] text-black text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] w-fit h-[40px] ">
                <img src={EditIcon} alt="edit" />
                Bearbeiten
              </button>
              <button className="bg-[#BF5547] text-white text-base px-4 py-2 rounded-[5px] flex items-center gap-[10px] max-w-fit w-fit">
                <img src={DeleteIcon} alt="delete" />
                Löschen
              </button> */}
            </div>

            <div className="bg-white p-5 rounded-[20px]">
              <div className="grid 	grid-template-columns: repeat(2, minmax(0, 2fr)) lg:grid-cols-3 md:grid-cols-2 gap-4 mb-5">
                <ScoreCard
                  title="Humanpotential"
                  score={averageMetrics.h.toFixed(2)}
                  icon={<InfoIcon className="w-4 h-4 text-gray-400" />}
                />
                <div className="bg-[#F2F6FA] p-4 rounded-lg">
                  <ColorBar value={averageMetrics.t.toFixed(2)} />
                  <div className="flex items-center  gap-2.5 mb-2 mt-2.5">
                    <h3 className="text-sm font-medium text-gray-500">
                      Ökonomische Temperatur
                    </h3>
                    <InfoIcon className="w-4 h-4 text-gray-400" />
                  </div>
                  <p className="text-2xl font-bold mb-2">
                    {averageMetrics.t.toFixed(2)}
                  </p>
                </div>
                <ScoreCard
                  title="Spezifität"
                  score={averageMetrics.mu.toFixed(2)}
                  icon={<InfoIcon className="w-4 h-4 text-gray-400" />}
                />
              </div>
              <div className="flex flex-col md:flex-row  gap-5 items-center mx-auto p-6 bg-[#F2F6FA] max-w-[1620px] rounded-lg">
                <div className="chart-container w-full md:w-3/4 mb-6 md:mb-0">
                  <MicroChart
                    graphData={chartData}
                    onSkillUpdate={handleSkillUpdate}
                  />
                </div>
                <div className="legend w-full md:w-1/4 md:ml-6 text-sm text-gray-500 mt-20">
                  <p className="mb-2">80 % NI - Experte</p>
                  <p className="mb-2">50 % NI - Umfassend</p>
                  <p className="mb-2">40 % NI - Sicher</p>
                  <p className="mb-2">30 % NI - Anwender</p>
                  <p className="mb-2">20 % NI - Grundkenntnisse</p>
                  <p className="mb-2">10 % NI - Keine Kenntnisse</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JobDetailPage;
